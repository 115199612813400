import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "./staffdashboard.css";
import Dashboard from "./Dashboard";



function StaffHome() {



  return (
    <>
      <Dashboard/>
    </>
  );
}
export default StaffHome;

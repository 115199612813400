import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL } from "../../../Global";
import printJS from 'print-js'
import { Modal } from 'react-bootstrap';
import { Button } from 'react-bootstrap';
import moment from 'moment'
import { useAlert } from "react-alert";
import pv from "../../../assets/images/ft_logo.png";
function PaymentInvoiceLiability(props) {
  // console.log(invoiceData,"invoiceData")
  const [due,setDue]=useState(props.due)
  const [totalOrderAmount, settotalOrderAmount] = useState(0)
  const [invoiceData, setInvoiceData] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [method, setMethod] = useState('')
  const [payment_to, setpayment_to] = useState('full_pay')
  const alert = useAlert();
  const [paidAmt,setPaidamt]=useState(null)
  const [discount, setDiscount] = useState(0.0);
  const [Ids, setID] = useState([]);
  const [error, setError] = useState({})
  const [reference, setreference] = useState("")
  const [remarks,setRemarks] = useState("")
  const [showreference, setShowreference] = useState(false)
  // const html2pdf = require('html2pdf.js');
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))
      ? JSON.parse(sessionStorage.getItem("logindatas"))
      : null
  );

  useEffect(() => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')
   
    axios.get(
      `${APIURL}/api/v1/staff/hospital-license-bills/`, {
      params: { 'bill_ids': props.id},
      headers: {
        "Content-Type": "application/json",
        Authorization: "Token " + str,
      }
    })
        .then(res => {

            if (res.data.status === "success") {
              setInvoiceData(res.data.message)
            }


        })
        .catch(err => {

        })
    

}, []);

useEffect(() => {if(payment_to!=="full_pay"){
  setDiscount(0)
}else if(payment_to!=="part_pay"){
  setPaidamt(null)
}
},[payment_to])
  const handleMethod = (e) => {
    setMethod(e.target.value)
    if (e.target.value === "upi"||e.target.value === "cash") {
      setShowreference(true)
      // setError((currentstate) => ({
      //   ...currentstate,
      //   reference: 'Enter payment reference',
      // }))
    }
    // else if(payment_to === "part_pay"&&(paidAmt===null||paidAmt<1)){
    //   setError((currentstate) => ({
    //     ...currentstate,
    //     paidAmt: 'Enter amount',
    //   }))
    // }
    else {
      setShowreference(false)
      setError((currentstate) => ({
        ...currentstate,
        reference: '',
      }))
    }
  }
 
  const handleSave = async () => {
    document.getElementById("save-btn").disabled = true;

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');
    if ( payment_to !== "waived"&&reference === "") {
      setError((currentstate) => ({
        ...currentstate,
        reference: 'Enter reference',
      }))
    }
    else if(payment_to === "part_pay"&&(paidAmt===null||paidAmt<1)){
        setError((currentstate) => ({
          ...currentstate,
          paidAmt: 'Enter amount',
        }))
      }
    else {
      const dataToUpdate = {
        bill_ids: props.id,
        hospital_id:props.hospId,
        "payment_mode":{method:payment_to==="waived"?0:payment_to==="part_pay"?Number(paidAmt):Number(due),
                    "payment_reference":reference},
        paid_amount:payment_to==="waived"?0:payment_to==="part_pay"?Number(paidAmt):Number(due),
        waived_off:payment_to==="waived"?true:false,
        patient_id: props.patid,
        // paid_status: paidAmt!==null&&totalOrderAmount>paidAmt?"":"",
        bill_discount: parseFloat((due * discount) / 100),
        bill_discount_percent: discount,
        // payment_mode: method,
        // payment_reference: reference,
        remarks:remarks

      }

      if (isSaving) {
        return;
      }
      setIsSaving(true);
      try {
        const res = await axios.post(`${APIURL}/api/v1/staff/hospital-license-payment/`, dataToUpdate,
          {
            headers: {
              'Content-Type': 'application/json',
              "Authorization": 'Token ' + str
            }
          })
          .then(res => {

            if (res.data.status === "success") {
              alert.success(res.data.message)
              handlePrint()
            } else {
              alert.error(res.data.message)
            }

          })
      } catch (err) {
        alert.error('Error in Data Submission.Please try again')

      }
      finally {
        setIsSaving(false);
      }
      document.getElementById("save-btn").disabled = false;
    }
  }

  const handlePrint = () => {
    printJS({
        printable: 'print-invoice',  // ID of the element to print
        type: 'html',
        targetStyles: ['*'],
    });
    props.onSuccess();
};
  // const handlePrint = () => {
  //   const selectedMethod = method;
  //   const selectedPaymentTo = payment_to;
  //   const element = document.getElementById('print-invoice');
  //   const fileName = `${props.name}_${props.id}.pdf`;

  //   const cloneElement = element.cloneNode(true);
  //   document.body.appendChild(cloneElement);

  //   html2pdf()
  //     .from(cloneElement)
  //     .set({
  //       filename: fileName,
  //       jsPDF: { format: 'a4' },
  //     })
  //     .outputPdf('blob')
  //     .then((pdfBlob) => {
  //       document.body.removeChild(cloneElement);

  //       setMethod(selectedMethod);
  //       setpayment_to(selectedPaymentTo);

  //       const url = window.URL.createObjectURL(pdfBlob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = fileName;
  //       a.click();
  //       window.URL.revokeObjectURL(url);

  //       printJS({
  //         printable: 'print-invoice',
  //         type: 'html',
  //         targetStyles: ['*'],
  //       });

  //       setTimeout(() => {
  //         props.dataHandle1();
  //       }, 1000);
  //     });
  // };
  // const handlePrint = () => {
  //   const element = document.getElementById('print-invoice');
  //   const fileName = `${props.name}_${props.id}.pdf`;

  //   // Generate PDF as a Blob
  //   html2pdf()
  //     .from(element)
  //     .set({
  //       filename: fileName,
  //       jsPDF: { format: 'a4' },
  //     })
  //     .outputPdf('blob')
  //     .then((pdfBlob) => {
  //       // Create a download link to manually save the file
  //       const url = window.URL.createObjectURL(pdfBlob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = fileName;
  //       a.click();
  //       window.URL.revokeObjectURL(url);
  //       // Trigger the print dialog
  //       printJS({
  //         printable: 'print-invoice',  // ID of the element to print
  //         type: 'html',
  //         targetStyles: ['*'],
  //       });
  //       setTimeout(() => {
  //         props.dataHandle1();
  //       }, 1000);
  //     });
  // };

 
  useEffect(() => {
    console.log("invoiceData", invoiceData)
    let totalAmount = invoiceData.reduce((accumulator, item) => {
      console.log(item)
      const discountedAmount = (item.total_license_amount) 
     
      console.log((accumulator + item.total_amount        ))
      return (accumulator + item.total_amount);
    }, 0);
  
    const finalTotal = (due) - (due * discount / 100);
    settotalOrderAmount(totalAmount)
    setDue(parseFloat(finalTotal.toFixed(2)));

  }, [invoiceData, discount]);
  const handlePayement = (e) => {
    setpayment_to(e.target.value)
  }
  return (<div >
    <div id='print-invoice' style={{ padding: '4%' }}>
      <div className='row'>
        <div className='col-9'>
          <div style={{ display: "flex", }}><img src={pv} width="15%" height="15%" alt="image" />&nbsp;&nbsp;</div>
          <div style={{ textAlign: "left" }}>
            
          </div>
        </div>

        <div className='col-2'>
          <h2 style={{ color: "blue" }} className="title-of-tasks"> INVOICE  </h2>
        </div>
      </div><br />
      <div className='row'>
        <div className='col-9'>
         


        </div>
        <div className='col-3'>
          <div style={{ textAlign: "left" }}>

            <small className="invoice-heading invoice-heading-normal">Date: {moment().format('DD-MM-YYYY')}</small><br />

          </div>


        </div>
      </div>
      <div style={{ marginBottom: "1%",margin:"auto" ,fontSize:"13px"}} className="row">
      <div style={{ backgroundColor: "rgba(85, 71, 159, 0.72)", color: "white", border: "1px solid white", borderRadius: "5px", }} className="col-3"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}> License</div></div>
      <div style={{ backgroundColor: "rgba(85, 71, 159, 0.72)", color: "white", border: "1px solid white", borderRadius: "5px", }} className="col-3"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}> Bill Cycle</div></div>
        <div style={{ backgroundColor: "rgba(85, 71, 159, 0.72)", color: "white", border: "1px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}> Issued Date</div></div>
       <div style={{ backgroundColor: "rgba(85, 71, 159, 0.72)", color: "white", border: "1px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>Qty</div></div>
        <div style={{ backgroundColor: "rgba(85, 71, 159, 0.72)", color: "white", border: "1px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>License amount</div></div>
        </div>
      
        {invoiceData.length>0&&invoiceData.map(item => {
        
          return (<>
          <div style={{ marginBottom: "1%",margin:"auto",fontSize:"13px" }} className="row">
<div style={{ backgroundColor: "rgb(237 236 224)", border: "1px solid white", borderRadius: "5px", }} className="col-3"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>{item.item_name}</div></div>
<div style={{ backgroundColor: "rgb(237 236 224)", border: "1px solid white", borderRadius: "5px", }} className="col-3"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>{item.plan_start_date} to {item.plan_end_date}</div></div>
           <div style={{ backgroundColor: "rgb(237 236 224)", border: "1px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>{item.bill_generated_date}</div></div>
           <div style={{ backgroundColor: "rgb(237 236 224)", border: "1px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>{item.quantity}</div></div>
            <div style={{ backgroundColor: "rgb(237 236 224)", border: "1px solid white", borderRadius: "5px", }} className="col-2"><div style={{ paddingTop: "2%", paddingBottom: "2%", textAlign: "center", }}>{item.total_amount}</div></div>
           
           
            </div></>
          )
        })}
      
      <br />
      <div className='row'>
        <div className ='col-8'>
        <div className='invoice-input1 row' >
           
            <div className="col-4">
              <input
                type="radio"
                id="full_pay"
                name="payment_to"
                value="full_pay"
                checked={payment_to === "full_pay"}
                onClick={handlePayement}
              />&nbsp;<div style={{ paddingTop: "9%" }}>
                <label htmlFor="full_pay">Full payment</label>
              </div>
            </div>

            <div className="col-4">
              <input
                type="radio"
                id="part_pay"
                name="payment_to"
                value="part_pay"
                checked={payment_to === "part_pay"}
                onClick={handlePayement}
              />&nbsp;<div style={{ paddingTop: "9%" }}>
                <label htmlFor="part_pay">Partial payment</label>
              </div>

            </div>
            <div className="col-4">
              <input
                type="radio"
                id="waived"
                name="payment_to"
                value="waived"
                checked={payment_to === "waived"}
                onClick={handlePayement}
              />&nbsp;<div style={{ paddingTop: "9%" }}>
                <label htmlFor="waived">waived off</label>
              </div>

            </div>
          </div><br />
           
          {payment_to==="full_pay" ?<> <span style={{ textAlign: "left" }} className='text-muted font-weight-bold col-4'>Discount in %:</span>&nbsp;
              <input

                type="number"
                maxLength="50"
                name="discount"
              className='clinc_input col-8'
                value={discount}
                onChange={(e) => {
                    setDue(props.due)
                  setDiscount(e.target.value > 0 && e.target.value < 100.1 ? e.target.value : "")

                }}
              /></>:payment_to==="part_pay"?<>
               <span style={{ textAlign: "left" }} className='text-muted font-weight-bold col-4'>Paid amount:</span>&nbsp;
              <input

                type="number"
                maxLength="50"
                name="discount"
                className={`input1 ${error.paidAmt ? 'error-border col-8' : 'clinc_input col-8'}`}
                // style={{
                //   border: "1px solid #CCCCCC",
                //   borderRadius: " 8px",
                //   // width: "60%",
                // }}
                value={paidAmt}
                onChange={(e) => {
                  document.getElementById("save-btn").disabled = false;
                  setPaidamt(e.target.value > 0 && e.target.value < due? e.target.value : alert.error("invalid amount"))

                }}
              /></>:""}
          

           
        </div>
        <div className='col-4  clinc_input' style={{margin:"auto"  }}>
        <div className='row'> 
          <span style={{ textAlign: "left" }} className='text-muted  col-5'> Total</span>
          <span className="col-7" style={{fontSize:"14px",textAlign: "left"}}><span className=""><b>RS{" "}</b></span><b>{totalOrderAmount}/-</b></span>
        </div>
        <div className='row'style={{marginTop:"2%"}} > 
          <span style={{ textAlign: "left" }} className='text-muted col-5'> Paid</span>
          <span className="col-7" style={{color:"green",fontSize:"14px",textAlign: "left"}}><span className=""><b>RS{" "}</b></span><b>{props.paid}/-</b></span>
        </div>
        
          <div className='row' style={{marginTop:"2%"}}> 
            <span style={{ textAlign: "left" }} className='text-muted col-5'>Payable</span>
            <span className="col-7" style={{color:"orange",fontSize:"18px",textAlign: "left"}}><b >RS{" "}{due}/-</b></span></div>
        </div>
      </div>


      <br />

     
    </div>
    <div className='row'>
      <div className='col-8'>
        <div className='flex-row invoice-radio' style={{paddingLeft:"2%"}}>
         
          <input type='radio' className='hideme' checked={method === 'cash'} id='cash' value='cash' onChange={handleMethod} />
          <label htmlFor='cash' className='invoice-label'>
           Bank
          </label>
          &nbsp;&nbsp;
          <input type='radio' className='hideme' checked={method === 'upi'} id='benefit_pay' value='upi' onChange={handleMethod} />
          <label htmlFor='benefit_pay' className='invoice-label'>
            UPI
          </label>
        </div>
        {showreference &&<div className='row' style={{ width: "100%" }}>  <span className='text-muted font-weight-bold col-4'></span> <input

          type="text"
          maxLength="50"
          placeholder={method==="upi"?'Enter payment reference':"Enter transaction reference"}
          name="payment_reference"
          className={`input1 ${error.reference ? 'error-border col-8' : 'clinc_input col-8'}`}
          value={reference}
          onChange={(e) => {
            document.getElementById("save-btn").disabled = false;
            setreference(e.target.value);
            if (e.target.value !== "") {
              setError((currentstate) => ({
                ...currentstate,
                reference: '',
              }))
            }
            else {
              setError((currentstate) => ({
                ...currentstate,
                reference: 'Enter reference',
              }))
            }

          }}
        /></div>}
        
        <div className='row' style={{ width: "100%",marginTop:"1%" }}>  <span className='text-muted font-weight-bold col-4'></span>
         <textarea

type="text"
maxLength="50"
placeholder='Remarks'
name="remarks"
className={'clinc_input col-8'}
value={remarks}
onChange={(e) => {
  document.getElementById("save-btn").disabled = false;
  setRemarks(e.target.value);
 
  
  

}}
/></div>
      </div>
      <div className='col-4'>
        <button  className="btn btn-primary btn-col btn-square" id="save-btn" onClick={()=>{if(payment_to !== "waived"&&method === ''){alert.error("select payment method")}else{handleSave()}}}> Save and Print </button>
      </div>
    </div>
    <br />
    <div className="do-not-print text-center" id="pr">


      <small style={{ color: "grey" }}>** If in case 'Print' does not work, please cross check if popup blocker is switched 'off' in your Browser settings.</small>

    </div>



  </div>
  )
}

export default PaymentInvoiceLiability

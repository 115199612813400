import { useLocation, Navigate,useNavigate } from "react-router-dom";
import axios from "axios";
import { APIURL,TZ } from "../../Global";


const getHomeURL = (userType,projType, hospital_pending) => {
    let homeURL = "/";
    if(userType==="config_admin"||userType==="staff"||userType==="sales_admin"){
        homeURL += hospital_pending ? "hospital_pending" : userType===null?"login":userType;
    }
   else if(projType==="home_care"||projType==="elder_care"){   
    homeURL += hospital_pending ? "hospital_pending" :userType===null?"login":userType;;
    }
    // else if((projType==="elder_care"&&userType === "staff")||
    // (projType==="elder_care"&&userType === "hospital")|| (projType==="elder_care"&&userType === "hospital_bdo")||(projType==="elder_care"&&userType === "hospital_doctor")){
    //     homeURL += hospital_pending ? "hospital_pending" : userType;
    // }
  
    else{
        homeURL += hospital_pending ? "hospital_pending" : "AppUser";
    }
    return homeURL;
}

const RequireAuth = ({ type, children }) => {
    // use hooks to check this : TODO
    const loggedIn = sessionStorage.getItem("usertoken");
    const userType = sessionStorage.getItem("usertype");
   const projType=sessionStorage.getItem("project_type")
    const hospital_pending = sessionStorage.getItem("hospital_pending");
    const location = useLocation();

    if (loggedIn && userType) {
        if ((userType === "staff" && type === "staff") ||
            
            (userType==="config_admin"&& type === "config_admin") ||
            (userType==="sales_admin"&& type === "sales_admin") 
           ) {
            return children;
        }
        else if( (projType==="elder_care" &&userType === "hospital_doctor" && type ==="appuser") ||
        (projType==="elder_care"&&userType === "hospital_bdo" && type ==="appuser")){
            return children;
        }
        return <Navigate to={getHomeURL(userType,projType, hospital_pending)} state={{ from: location }} />;
    }

   
      

return <Navigate to="/login" state={{ from: location }} />;
    
}

export default RequireAuth;
import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
 import '../userprofile.css';

 import { useAlert } from 'react-alert';
import { Card, Button, Modal } from 'react-bootstrap'
import { Link, useParams,useLocation, useNavigate } from 'react-router-dom';
import LiabilitiesAll from './LiabilitiesAll';
import Bills from './Bills';


function ClinicsMngmntHome() {
  const alert = useAlert();
  
  
 const [selected,setSelected]=useState("Liabilities")
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas")) || null
  );
  let navigate = useNavigate();


 


 return (<>
 <div className='row ' style={{marginTop:"1%",width:"100%",minHeight:"71vh"}}>


 
 <div className="col-3">
 <h6 class="" style={{paddingLeft:"4%",textTransform:"capitalize"}} ><b>{}</b></h6>
    <div className="det-div " style={{padding:"1%"}}>
   
    <button className={selected==="Liabilities"?"patientRecbutton1":"patientRecbutton"}
style={{width:"100%",cursor: "pointer"}}
  onClick={() => {
   setSelected("Liabilities")
  }}
>
  &nbsp;
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48"><path fill="currentColor" fill-rule="evenodd" d="M20 4a3 3 0 0 0-3 3h-3a3 3 0 0 0-3 3v28a3 3 0 0 0 3 3h12v-2H14a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h3a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3h3a1 1 0 0 1 1 1v15h2V10a3 3 0 0 0-3-3h-3a3 3 0 0 0-3-3h-8Zm-1 3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1V7Zm4 12v-3h2v3h3v2h-3v3h-2v-3h-3v-2h3Zm4 9a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6.789a7 7 0 0 1-3.117 5.824l-3.328 2.22a1 1 0 0 1-1.11 0l-3.328-2.22A7 7 0 0 1 27 34.79V28Zm2 1v5.789a5 5 0 0 0 2.227 4.16L34 40.8l2.773-1.85A5 5 0 0 0 39 34.79V29H29Zm4.707 7.707l4-4l-1.414-1.414L33 34.586l-1.293-1.293l-1.414 1.414l2 2a1 1 0 0 0 1.414 0Z" clip-rule="evenodd" /></svg>
  &nbsp;
 Liabilities{" "}

</button>
<button className={selected==="bills"?"patientRecbutton1":"patientRecbutton"}
style={{width:"100%",cursor:login_datas.user_type === "staff"?"not-allowed": "pointer"}}
  onClick={() => {login_datas.user_type !== "staff"&&
   setSelected("bills")
  }}
>
  &nbsp;
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48"><path fill="currentColor" fill-rule="evenodd" d="M20 4a3 3 0 0 0-3 3h-3a3 3 0 0 0-3 3v28a3 3 0 0 0 3 3h12v-2H14a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h3a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3h3a1 1 0 0 1 1 1v15h2V10a3 3 0 0 0-3-3h-3a3 3 0 0 0-3-3h-8Zm-1 3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1V7Zm4 12v-3h2v3h3v2h-3v3h-2v-3h-3v-2h3Zm4 9a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6.789a7 7 0 0 1-3.117 5.824l-3.328 2.22a1 1 0 0 1-1.11 0l-3.328-2.22A7 7 0 0 1 27 34.79V28Zm2 1v5.789a5 5 0 0 0 2.227 4.16L34 40.8l2.773-1.85A5 5 0 0 0 39 34.79V29H29Zm4.707 7.707l4-4l-1.414-1.414L33 34.586l-1.293-1.293l-1.414 1.414l2 2a1 1 0 0 0 1.414 0Z" clip-rule="evenodd" /></svg>
  &nbsp;
 Paid Bills{" "}

</button>
{/* <button className={selected==="pricing"?"patientRecbutton1":"patientRecbutton"}
style={{width:"100%",cursor:login_datas.user_type === "staff"?"not-allowed": "pointer"}}
  onClick={() => {login_datas.user_type !== "staff"&&
   setSelected("pricing")
  }}
>
  &nbsp;
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a2 2 0 1 0 0 4a2 2 0 0 0 0-4Zm-4 8.5a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1Z" /><path fill="currentColor" fill-rule="evenodd" d="M7 2.25A2.75 2.75 0 0 0 4.25 5v14A2.75 2.75 0 0 0 7 21.75h10A2.75 2.75 0 0 0 19.75 19V8.198a1.75 1.75 0 0 0-.328-1.02L16.408 2.98a1.75 1.75 0 0 0-1.421-.73H7ZM5.75 5c0-.69.56-1.25 1.25-1.25h7.25v4.397c0 .414.336.75.75.75h3.25V19c0 .69-.56 1.25-1.25 1.25H7c-.69 0-1.25-.56-1.25-1.25V5Z" clip-rule="evenodd" /></svg>
  &nbsp;
  Pricing{" "}

</button>
<button className={selected==="liabilities"?"patientRecbutton1":"patientRecbutton"}
style={{width:"100%",cursor:login_datas.user_type === "staff"?"not-allowed": "pointer",}}
  onClick={() => {login_datas.user_type !== "staff"&&
   setSelected("liabilities")
  }}
>
  &nbsp;
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a2 2 0 1 0 0 4a2 2 0 0 0 0-4Zm-4 8.5a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1Z" /><path fill="currentColor" fill-rule="evenodd" d="M7 2.25A2.75 2.75 0 0 0 4.25 5v14A2.75 2.75 0 0 0 7 21.75h10A2.75 2.75 0 0 0 19.75 19V8.198a1.75 1.75 0 0 0-.328-1.02L16.408 2.98a1.75 1.75 0 0 0-1.421-.73H7ZM5.75 5c0-.69.56-1.25 1.25-1.25h7.25v4.397c0 .414.336.75.75.75h3.25V19c0 .69-.56 1.25-1.25 1.25H7c-.69 0-1.25-.56-1.25-1.25V5Z" clip-rule="evenodd" /></svg>
  &nbsp;
  Liabilities{" "}

</button> */}




</div>
    </div>
    <div className="col-9" >
  {selected==="Liabilities"? <div>
  <LiabilitiesAll/>
  </div> 
  :selected==="bills"?
  <div>
    <Bills/>
  </div>
  :selected==="pricing"?""
:""}

     

    </div>
    </div>
  </>

  )}



export default ClinicsMngmntHome

import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { APIURL, TZ } from "../../../Global";
function LicenseConfig() {
    const [data, setData] = useState([])
    useEffect(() => {

        const tokenString = sessionStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '')


        axios.get(`${APIURL}/api/v1/staff/master-licenses/?${TZ}`, {
            headers: {

                "Authorization": 'Token ' + str
            }
        })
            .then(res => {

                if (res.data.status === "success") {

                    setData(res.data.message)

                }


            })
            .catch(err => {

            })


    }, []);
    return (
        <div >
            <div className="" style={{}}>
                <div className="row " style={{ backgroundColor: "rgba(85, 71, 159, 0.72)", color: "white", textAlign: "left", padding: "1%", borderRadius: "5px 5px 0px 0px" }}>
                    <div className="col-3" style={{ textAlign: "center", borderRight: "0.4px solid white" }}></div>
                    {data.length > 0 && data.map((item) => {
                        return (<>
                            <div className="col-3" style={{ textAlign: "center", borderRight: "0.4px solid white", }}><div style={{ borderBottom: "0.4px solid white" }}><b>{item.version_name}</b></div>
                                <div style={{ textAlign: "left" }}><b >Free count</b><b style={{ float: "right" }}>Rate</b></div>
                            </div>

                        </>)
                    })}
                </div>
                <div className="row" style={{ textAlign: "left" }}>

                    {[
                        ...new Set(
                            data.flatMap((version) =>
                                version.licenses.map((license) => license.license_name)
                            )
                        ),
                    ].map((licenseName) => (
                        <React.Fragment key={licenseName}>
                            <div
                                style={{
                                    backgroundColor: "#433c64",
                                    color: "gold",
                                    padding: "1%",
                                    borderRight: "0.4px solid gray",
                                }}
                                className="col-3"
                            >
                                {licenseName}
                            </div>
                            {data.map((version) => {
                                const license = version.licenses.find(
                                    (l) => l.license_name === licenseName
                                );
                                return license ? (
                                    <div
                                        key={license.id}
                                        style={{
                                            padding: "1%",
                                            borderRight: "0.4px solid gray",
                                            borderBottom: "0.4px solid gray",
                                            borderTop: "0.4px solid gray",
                                        }}
                                        className="col-3"
                                    >
                                        <span style={{ paddingLeft: "2%" }}>
                                            {license.free_user_count}
                                        </span>
                                        <span style={{ float: "right" }}>
                                            Rs {license.extra_user_price}/-
                                        </span>
                                    </div>
                                ) : (
                                    <div
                                        key={`${version.version_name}-${licenseName}`}
                                        className="col-3"
                                        style={{
                                            padding: "1%",
                                            borderRight: "0.4px solid gray",
                                            borderBottom: "0.4px solid gray",
                                            borderTop: "0.4px solid gray",
                                        }}
                                    >
                                        <span style={{ paddingLeft: "2%" }}>-</span>
                                        <span style={{ float: "right" }}>-</span>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
}
export default LicenseConfig;

import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { APIURL, TZ } from "../../../Global";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
function Plans() {
    const [data, setData] = useState([])
    useEffect(() => {

        const tokenString = sessionStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '')


        axios.get(`${APIURL}/api/v1/staff/master-versions/?${TZ}`, {
            headers: {

                "Authorization": 'Token ' + str
            }
        })
            .then(res => {

                if (res.status === 200) {

                    setData(res.data)

                }


            })
            .catch(err => {

            })


    }, []);
    return (
        <div >
            <div className="" style={{marginBottom:"1%"}}>
                <div className="row " style={{ backgroundColor: "rgba(85, 71, 159, 0.72)", color: "white", textAlign: "left", padding: "1%", borderRadius: "5px 5px 0px 0px" }}>
                   
                    {data.length > 0 && data.map((item) => {
                        return (<>
                            <div className="col-4" style={{ textAlign: "center",padding:"1%" }}><div ><b>{item.version_name}</b></div>
                              
                            </div>

                        </>)
                    })}
                </div>
                <div className="row" style={{ textAlign: "left" }}>
{data.map((item)=>{
    return(
       
        <div
       
        style={{
            padding: "1%",
            
           border:"0.4px solid gray",
        }}
        className="col-4"
    > <SimpleBar style={{ maxHeight: "300px", width: "100%" }}>{item.feature_name.map((i)=>{return(<><span>{i}</span><br/></>)})} </SimpleBar></div>
   
    )
})}
                </div>
            </div>
        </div>
    );
}
export default Plans;

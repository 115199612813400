import React, { createContext, useContext, useState } from 'react';

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const [isApiSuccess, setIsApiSuccess] = useState(false);

  return (
    <ApiContext.Provider value={{ isApiSuccess, setIsApiSuccess }}>
      {children}
    </ApiContext.Provider>
  );
};

export const useApiContext = () => useContext(ApiContext);

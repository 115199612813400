import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { APIURL, TZ } from "../../../Global";
import "bootstrap/dist/css/bootstrap.min.css";
import "./clinics.css"
import { useAlert } from 'react-alert';
import Plans from "../SalesManagement/Plans";
function Pricing(props) {
    const alert = useAlert()
    const [master, setMaster] = useState([])
    const [plans, setPlans] = useState({})
    const [common, setCommon] = useState({})
    const [basic, setBasic] = useState([])
    const [pro, setPro] = useState([])
    const [licenses, setLicenses] = useState([])
    const [enterprise, setEnterprice] = useState([])
    const [basicEdit, setBasicEdit] = useState(false)
    const [proEdit, setProEdit] = useState(false)
    const [enterpriseEdit, setEnterpriceEdit] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [refresh1, setRefresh1] = useState(false)
    useEffect(() => {setRefresh1(!refresh1)},[props.id])
    useEffect(() => {

        const tokenString = sessionStorage.getItem('usertoken');

        let str = tokenString.replace(/["]+/g, '')
        setBasic([])
        setPro([])
        setEnterprice([])
        axios.get(`${APIURL}/api/v1/staff/hospital-plans/?hospital_id=${props.id}&${TZ}`, {
            headers: {

                "Authorization": 'Token ' + str
            }
        })
            .then(res => {

                if (res.data.status === "success") {
                    setPlans(res.data.details)
                }


            })
            .catch(err => {

            })
        axios.get(`${APIURL}/api/v1/staff/master-licenses/?${TZ}`, {
            headers: {

                "Authorization": 'Token ' + str
            }
        })
            .then(res => {

                if (res.data.status === "success") {

                    setMaster(res.data.message)

                }


            })
            .catch(err => {

            })


    }, [refresh1]);
    const handleSave = () => {
       
        const id = props.id;
        const tokenString = sessionStorage.getItem("usertoken");
        const filteredData1 = master.filter(item => {
            return item.version_name === "Basic"
        })
        const filteredData2 = master.filter(item => {
            return item.version_name === "Pro"
        })
        const filteredData3 = master.filter(item => {
            return item.version_name === "Enterprise"
        })
        let str = tokenString.replace(/["]+/g, "");
    let lic= []
 console.log(filteredData1,"filteredData1")
 const dataToUpdate = {
    hospital_id: id,
    free_period: Number(common.free_period),
    plan_period: "yearly",
    amount: 0,
    version: basicEdit ? filteredData1[0].version : proEdit ? filteredData2[0].version : filteredData3[0].version,
    licenses: licenses.map((license) => ({
        license_id: license.id, // Replace license_name with license id
        free_users: Number(license.free_user_count) || 0, // Map to free_users
        extra_user_price: Number(license.extra_user_price) || 0, // Keep the same
    })),
};
        
    
        axios
          .post(`${APIURL}/api/v1/staff/hospital-plans/`, dataToUpdate, {
            headers: {
              Authorization: "Token " + str,
              "Content-Type": "application/json",
            },
          })
    
          .then((res) => {
            
            if (res.data.status === "success") {
              alert.success("successfully added the license")
              props.handleAdd();
             } else {
              alert.error(res.data.message);
            }
          })
          .catch((err) => {
            alert.error("error in submission")
          });
        
      };
    useEffect(() => {
        if (plans.version) {
            setLicenses(plans.licenses)
        }
        else {
            basicEdit ? setLicenses(basic) : proEdit ? setLicenses(pro) : enterpriseEdit ? setLicenses(enterprise) : setLicenses([])
        }
    }, [proEdit, basicEdit, enterpriseEdit])

    useEffect(() => {
        if (plans.version) {
            if (plans.version === "Basic") {
                setBasic(plans.licenses)
                setLicenses(plans.licenses)
            }
            else if (plans.version === "Pro") {
                setPro(plans.licenses)
                setLicenses(plans.licenses)
            }
            else if (plans.version === "Enterprise") {
                setEnterprice(plans.licenses)

            }
         
            setCommon({ free_period: plans.free_period, plan_type: plans.plan_type})
        }
        else {
            if (master.length > 0) {
                const filteredData1 = master.filter(item => {
                    return item.version_name === 'Basic'
                })
                const filteredData2 = master.filter(item => {
                    return item.version_name === 'Pro'
                })
                const filteredData3 = master.filter(item => {
                    return item.version_name === 'Enterprise'
                })
                console.log(filteredData1, "filteredData1")
                setBasic(filteredData1[0].licenses)
                setPro(filteredData2[0].licenses)
                setEnterprice(filteredData3[0].licenses)
                setCommon({ free_period: 0, plan_type: "saas" })
            }
        }
    }, [plans, master, refresh])
    const displayLicense = (i, item, edit) => {
       
    
        return (
            <div className="col-12 col-sm-6 col-md-6 col-lg-6" style={{ padding: "1%" }}>
                <div style={{ border: "1px solid gray", borderRadius: "5px", backgroundColor: "#cbdadb", padding: "1%" }}>
                    <div className="d-flex justify-content-between">
                        <b>{item?.license_name || "N/A"}</b>
                        {edit ? (
                            <>
                                RS
                                <input
                                    className="clinc_input"
                                    type="number"
                                    placeholder=""
                                    value={licenses?.[i]?.extra_user_price || ""}
                                    style={{ width: "20%" }}
                                    onChange={(e) => {
                                        const newValue = e.target.value;
                                        setLicenses((currentState) =>
                                            currentState.map((item, index) =>
                                                index === i ? { ...item, extra_user_price: newValue } : item
                                            )
                                        );
                                    }}
                                />
                                /-
                            </>
                        ) : (
                            <>&nbsp;<b>RS {item?.extra_user_price || 0}/-</b></>
                        )}
                    </div>
    
                    <div style={{ backgroundColor: "white", padding: "1%" }}>
                        <div className="row">
                            <small className="col-7">Free user count</small>
                            {edit ? (
                                <input
                                    className="clinc_input"
                                    type="number"
                                    placeholder=""
                                    value={licenses?.[i]?.free_user_count || licenses?.[i]?.free_users }
                                    style={{ width: "20%" }}
                                    onChange={(e) => {
                                        const newValue = e.target.value;
                                        setLicenses((currentState) =>
                                            currentState.map((item, index) =>
                                                index === i ? { ...item, free_user_count: newValue } : item
                                            )
                                        );
                                    }}
                                />
                            ) : (
                                <b className="col-4">
                                    {item?.free_user_count || item?.free_users || 0}
                                </b>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    
    
    return (<div className="row">

        <div className={plans.version?"col-12":"col-9"}>
        <div className="" style={{ padding: "1%",minHeight:"71vh" }}>
           
           {(plans.version&&plans.version==="Basic")||(!plans.version) ? <div className="" style={{ border: "1px solid gray", borderRadius: "5px", backgroundColor: "#FDF5EE", padding: "3%", marginBottom: "1%" }}>
                 <h6 className="row">
                     <div className="col-2"><b >BASIC</b></div>
                     <div className="col-8 row">
 {plans.version&& <> <div className="col-6"><span className="text-left ">Free period</span>
                         <>&nbsp;<b>{common.free_period}</b>&nbsp;</>days</div>
                         <div className="col-6"> <p className="text-left  ">Plan type:
                         <>&nbsp;<b className="">{plans.plan_type ? plans.plan_type : "saas"}</b>&nbsp;</></p>
                         </div>
                         </>}
 
 
                     </div>
                     {!plans.version&&<div className="col-2">
                         <button disabled={plans.version} onClick={(e) => {setBasicEdit(!basicEdit); setProEdit(false); setEnterpriceEdit(false); setRefresh(!refresh) }
                         } style={{ backgroundColor: basicEdit && "red",width:"100%",cursor:plans.version?"not-allowed":"pointer" }} className="clinic_button">{basicEdit ? "Cancel" : "Select"}</button>
                     </div>}
                     {/* <div className="col-2">
                     <button disabled={!basicEdit}onClick={(e) => { handleSave() }
                         } style={{ backgroundColor: "#55479F",width:"100%" }} className="clinic_button">Add license</button>
                     </div> */}
                 </h6>
                 <div className="container-fluid">
                     <div className="row">
                         {basic.length > 0 && basic.map((item, index) => {
                             return (
                                 displayLicense(index, item, basicEdit)
                             )
                         })}
 
                     </div>
                 </div>
 
             </div>:""}
             {(plans.version&&plans.version==="Pro")||(!plans.version) ?<div className="" style={{ border: "1px solid gray", borderRadius: "5px", backgroundColor: "#F9FEF8", padding: "3%", marginBottom: "1%" }}>
                 <h6 className="row">
                     <div className="col-2"><b >Pro</b></div>
                     <div className="col-8 row">
 {plans.version&& <> <div className="col-6"><span className="text-left ">Free period</span>
                         <>&nbsp;<b>{common.free_period}</b>&nbsp;</>days</div>
                         <div className="col-6"> <p className="text-left  ">Plan type:
                         <>&nbsp;<b className="">{plans.plan_type ? plans.plan_type : "SaaS"}</b>&nbsp;</></p>
                         </div>
                         </>}
 
 
                     </div>
                     <div className="col-2">
                         <button onClick={(e) => { (setProEdit(!proEdit)); setBasicEdit(false); setEnterpriceEdit(false); setRefresh(!refresh) }
                         } style={{ backgroundColor: proEdit && "red",width:"100%" }} className="clinic_button">{proEdit ? "Cancel" : "Select"}</button>
                         </div>
                         {/* <div className="col-2">
                         <button disabled={!proEdit}onClick={(e) => {  handleSave() }
                         } style={{ backgroundColor: "#55479F",width:"100%" }} className="clinic_button">Add license</button>
                     </div> */}
                 </h6>
                 <div className="container-fluid">
                     <div className="row">
                         {pro.map((item, index) => {
                             return (
                                 displayLicense(index, item, proEdit)
                             )
                         })}
 
                     </div>
                 </div>
             </div>:""}
             {(plans.version&&plans.version==="Enterprise")||(!plans.version) ?<div className="" style={{ border: "1px solid gray", borderRadius: "5px", backgroundColor: "#FAF1F3", padding: "3%", marginBottom: "1%" }}>
                 <h6 className="row">
                     <div className="col-2"><b >Enterprise</b></div>
                     <div className="col-8 row">
 {plans.version&& <> <div className="col-6"><span className="text-left ">Free period</span>
                         <>&nbsp;<b>{common.free_period}</b>&nbsp;</>days</div>
                         <div className="col-6"> <p className="text-left  ">Plan type:
                         <>&nbsp;<b className="">{plans.plan_type }</b>&nbsp;</></p>
                         </div>
                         </>}
 
 
                     </div>
                     <div className="col-2">
                         <button onClick={(e) => { (setEnterpriceEdit(!enterpriseEdit)); setBasicEdit(false); setProEdit(false); setRefresh(!refresh) }
                         } style={{ backgroundColor: enterpriseEdit && "red" ,width:"100%"}} className="clinic_button">{enterpriseEdit ? "Cancel" : "Select"}</button>
                     </div>
                     {/* <div className="col-2">
                         <button disabled={!enterpriseEdit}onClick={(e) => {  handleSave() }
                         } style={{ backgroundColor: "#55479F",width:"100%" }} className="clinic_button">Add license</button>
                     </div> */}
                 </h6>
                 <div className="container-fluid">
                     <div className="row">
                         {enterprise.map((item, index) => {
                             return (
                                 displayLicense(index, item, enterpriseEdit)
                             )
                         })}
 
                     </div>
                 </div>
             </div>:""}
 
 
         </div>
        </div>
      {!plans.version&&  <div className="col-3" style={{padding:"0.5%"}}>
        <div className="det-div " style={{padding:"2%"}}>
                
                <div className="">
                    <span className="">Free period</span><br/>
                          <input
                            className="clinc_input"
                            type="text"
                            maxLength="100"
                            placeholder="in days"
                            value={common.free_period}
                            style={{ width: "100%" }}
                            onChange={(e) => setCommon((currentstate) => ({
                                ...currentstate,
                                free_period: e.target.value,
                            })
                            )}
                        /><br/><br/>
                    <span className="">Plan type:</span>
                       
                            <select
                                name='allergytype'
                                className="clinc_input"
                                style={{ padding: "auto", marginTop: "0.5%" ,width:"100%"}}
                                value={common.plan_type}
                                onChange={(e) => {
                                    setCommon((currentstate) => ({
                                        ...currentstate,
                                        plan_type: e.target.value,
                                    }))
                                }}>
                                <option value="" selected hidden  >Select type</option>
                                <option value="saas" >SaaS mode</option>
                                <option value="full_pay" >Full pay mode</option>


                            </select> 


                </div><br/>
                <div className="">
                    <button  onClick={(e) => {if(!basicEdit&&!proEdit&&!enterpriseEdit){alert.error("Choose any plan!!")}else{ handleSave()} }
                        } style={{ backgroundColor: "#55479F",width:"100%" }} className="clinic_button">Add license</button>
                    </div>

            </div>
        </div>}

        
    </div>
    );
}
export default Pricing;

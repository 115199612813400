import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { APIURL,TZ } from "../../Global";
import "./footer.css";
import { useTranslation } from "react-i18next";
import moment from 'moment'
import SimpleBar from "simplebar-react";
import { logo } from "../../assets/images/icons/cp_logo.jpg";
// import orgData from "../../felixaconfig.json"
function CallForAction(props) {
  const [login_datas, setLogin_datas] = useState(
     JSON.parse(sessionStorage.getItem("logindatas")) || null
   );
 const [reqLic,setReqLic]=useState([])
 let navigate = useNavigate();

  useEffect(() => {
    const tokenString = sessionStorage.getItem("usertoken");
    let str = tokenString.replace(/["]+/g, "");
    axios
      .get(`${APIURL}/api/v1/staff/requested-licenses/`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },})
      .then((res) => {
        if (res.data.status === "success") {
         setReqLic(res.data.requested_licenses)
        } else {
        }
      })
      .catch((err) => {});
  }, []);

  return (
   <div>
    <SimpleBar style={{ maxHeight: "300px", width: "100%" }}>
{reqLic.length>0?reqLic.map((item)=>{
    return(

    <div onClick={() => {props.onClose();navigate(`/hospital-details/${item.hospital_id}`, {
        state: {
          selected:login_datas.user_type === "staff"?"home": "license",
        }
      })}}style={{ backgroundColor: "rgba(255, 251, 237, 1)" }} className="callaction_lists" >
    <div className="col-10" style={{ paddingLeft: "2%" }}>
        <h6 style={{ textTransform: "capitalize" }}><b>{item.hospital_name}</b> - <span style={{ color: "red" }}>{item.license_name}</span></h6>
        <h6 style={{ textTransform: "capitalize" }}><b>{moment(item.purchased_date).format('DD-MM-YYYY')}</b></h6>
        
    </div>
    <div className="col-2" style={{ padding: "1%" }}>
      
    </div>
</div>)
}):<div className="" style={{ marginTop: "10%", color: "red" }}>No alerts!!</div>}
</SimpleBar>
   </div>
  );
}

export default CallForAction;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIURL, TZ } from "../../../Global";
import { Link, useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import "./featurelist1.css";

// import Pagination from "react-js-pagination";
// import "./pagination.css";
import AddMedicine from "./Addmedicine";
import EditMedicine from "./EditMedicine";
import Pagination from "react-js-pagination";
import LoadingOverlay from "react-loading-overlay";
import { useAlert } from 'react-alert'
import ReactTable from 'react-table-v6';
import 'react-table-v6/react-table.css'
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
function PharmMedicine() {
  const alert = useAlert()
  let navigate = useNavigate();
  const [infoData1, setInfoData1] = useState(""); 
  const [loading, setLoading] = useState(false);
  const [featureList, setFeatureList] = useState([]);
  
  const [searchTermMed, setSearchTermMed] = useState("");
  const [searchTermATC, setSearchTermATC] = useState("");
 
  const [page, setPage] = useState(1);
  const [action, setAction] = useState("")
  const [perPage, setPerPage] = useState(25);
  const [editShow, setEditShow] = useState(false);
  const [current, setCurrent] = useState();
  const [refresh, setRefresh] = useState(false);
  const [totalPages, setTotalPages] = useState(1); // Initialize to 1 as a default
  const [addRentalShow, setAddRentalShow] = useState(false);
  const [visiblePages, setVisiblePages] = useState([]);
  const [pagesToShow, setPagesToShow] = useState(4);
  const [category, setCategory] = useState(2);
  const [drugInt, setdrugInt] = useState(false);
  const [MedConfirmModalShow, setMedConfirmModalShow] = useState(false);
  const [flag, setFlag] = useState(false);
  const [successShow, setSuccessShow] = useState(false);
  const [id, setID] = useState()
  const [infoShow, setinfoShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   console.log(featureList1); // This will log the updated featureList1 when it changes
  // }, [featureList1]);
  useEffect(() => {
    // Generate visible page numbers based on the current page and total pages
    let startPage = Math.max(1, page - Math.floor(pagesToShow / 2));
    let endPage = startPage + pagesToShow - 1;

    if (endPage > totalPages) {
      endPage = totalPages;
      startPage = Math.max(1, endPage - pagesToShow + 1);
    }

    const newVisiblePages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    setVisiblePages(newVisiblePages);
  }, [page, totalPages, pagesToShow]);



  useEffect(() => {

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); /* REGEX To remove double quotes from tokenstring */


    axios
      .get(
        `${APIURL}/api/v1/doctor/medicine-search/?med_name=${searchTermMed}&med_code=${searchTermATC}&page=${page}&per_page=${perPage}&${TZ}`,
        {
          headers: { Authorization: "Token " + v2 },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          // setMedicine(res.data[search][0].options);
          const datas = res.data.medicines;
          console.log(datas, "datas")
          setFeatureList((prevData) => {
            const newData = datas.filter((item) => !prevData.some((existingItem) => existingItem.id === item.id));
            // const newData1 = newData.filter((item) => item.interactions !== null);
            return [...prevData, ...newData];

          });
          // setFeatureList(datas);
          setTotalPages(res.data.total_pages);
          setPage(res.data.page)

          setTotalRows(res.data.total_records);
          //  setDisplay(true);

        } else {
        }
      })
      .catch((error) => { });

  }, [searchTermMed,searchTermATC,refresh]);



  const rentalModalClose = () => {
    setAddRentalShow(false);
    setRefresh(!refresh);
    setFlag(true);
  };

  const notProceedClose = () => {
    setAddRentalShow(false);
    setRefresh(!refresh);
    setEditShow(false);
  };

  const modalClose = () => {
    setEditShow(false);
    setRefresh(!refresh);
  };

  const handleClose = () => {
    setRefresh(!refresh)
    setAddRentalShow(false);
    setEditShow(false);
  };

  const DeleteConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Are you sure you want to delete?</h4>
        </Modal.Body>
        <Modal.Footer>


          {/* <Button
            variant="danger"
            onClick={() => {
              handleDelete(deleteIndex);
            }}
          >
            Confirm
          </Button> */}
        </Modal.Footer>
      </Modal>
    );
  };
  const SubmitPopUp = ({ onHide }) => {
    return (
      <Modal
        show
        onHide
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-success">medicine Deleted Successfully!!</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const AddRentalPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header style={{ padding: "0px", paddingRight: "2%" }} closeButton>
          <h4 className="title-of-page">Add Medicine</h4>
        </Modal.Header >
        <Modal.Body>
          <AddMedicine
            modalClose={rentalModalClose}
            notProceedClose={notProceedClose}
          />
        </Modal.Body>

      </Modal>
    );
  };
  const handleDeleteMed = () => {


    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '');

    let dataToSend = {}
    if (action === "in_stock") {
      dataToSend = {
        in_stock: false
      }
    } else if (action === "out_stock") {
      dataToSend = {
        in_stock: true
      }
    } else if (action === "active") {
      dataToSend = {
        is_active: false
      }
    } else if (action === "inactive") {
      dataToSend = {
        is_active: true
      }
    }
    axios.put(`${APIURL}/api/v1/service-provider/master-medicine-detail/${id}/`, dataToSend, {
      headers: {
        "Content-Type": "application/json",

        Authorization: 'Token ' + str,
      }


    })



      .then(res => {

        if (res.data.status === "success") {

          alert.success(res.data.message)
          featureList.splice((page-1)*perPage, featureList.length + 1)
          setRefresh(!refresh)
        }
        else {
          alert.error(res.data.message)


        }

      })
      .catch(err => {

        // setSubmitMsg("error");

      })


  }

  const EditPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EditMedicine data={current} modalClose={()=>{featureList.splice((page-1)*perPage, featureList.length + 1);setRefresh(!refresh);modalClose()}} />
        </Modal.Body>
       
      </Modal>
    );
  };

  const handleEdit = (item) => {
    // const list = featureList[index];
    setCurrent(item);
    setEditShow(true);
  };

 
  const handlePageChange = (pageIndex) => {
    const nextPage = pageIndex + 1; // Convert 0-based index to 1-based page number
    if (pageIndex >= 0 && nextPage <= Math.ceil(totalRows / perPage)) {
      setPage(nextPage);

      setRefresh(!refresh)// Fetch data for the new page
    }
  };
  const InfoPopup1 = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
        <span  >{infoData1.med_name.charAt(0).toUpperCase() + (infoData1.med_name).slice(1).toLowerCase()}</span>
       
        </Modal.Header>
        <Modal.Body>{drugInt?
        <div style={{textAlign:"left", border: "2px solid #e4dd9f5c",}}>
        <div style={{paddingLeft:"2%" }} className="row ">
           <div style={{ backgroundColor: "#e4dd9f5c", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>Drug interactions</div></div>
           <div style={{  border: "2px solid white", borderRadius: "5px", }} className="col-8"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}> {infoData1.med_interactions}</div></div>
              </div>
              <div style={{ paddingLeft:"2%"}} className="row">
           <div style={{ backgroundColor: "#e4dd9f5c", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>URL</div></div>
           <div style={{ border: "2px solid white", borderRadius: "5px", }} className="col-8"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}> {infoData1.med_url}</div></div>
              </div>
                    
        </div>
        :
          <div style={{textAlign:"left", border: "2px solid #e4dd9f5c",}}>
          <div style={{paddingLeft:"2%" }} className="row ">
             <div style={{ backgroundColor: "#e4dd9f5c", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>Composition</div></div>
             <div style={{  border: "2px solid white", borderRadius: "5px", }} className="col-8"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}> {infoData1.composition}</div></div>
                </div>
                <div style={{ paddingLeft:"2%"}} className="row">
             <div style={{ backgroundColor: "#e4dd9f5c", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>Strength</div></div>
             <div style={{ border: "2px solid white", borderRadius: "5px", }} className="col-8"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}> {infoData1.strength}{infoData1.strength_unit}</div></div>
                </div>
                <div style={{ paddingLeft:"2%" }} className="row">
             <div style={{ backgroundColor: "#e4dd9f5c", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>Dosage form</div></div>
             <div style={{ border: "2px solid white", borderRadius: "5px", }} className="col-8"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}> {infoData1.dosage_form.replace(/_/g, ' ')}</div></div>
                </div>
                <div style={{ paddingLeft:"2%" }} className="row">
             <div style={{ backgroundColor: "#e4dd9f5c", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>Medicine type</div></div>
             <div style={{ border: "2px solid white", borderRadius: "5px", }} className="col-8"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}> {infoData1.med_type.replace(/_/g, ' ')}</div></div>
                </div>
                <div style={{paddingLeft:"2%" }} className="row">
             <div style={{ backgroundColor: "#e4dd9f5c", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>Route of medicine</div></div>
             <div style={{  border: "2px solid white", borderRadius: "5px", }} className="col-8"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}> {infoData1.route?infoData1.route.replace(/_/g, ' ').replace(/-/g, ' '):""}</div></div>
                </div>
                <div style={{ paddingLeft:"2%" }} className="row">
             <div style={{ backgroundColor: "#e4dd9f5c", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>Description</div></div>
             <div style={{ border: "2px solid white", borderRadius: "5px", }} className="col-8"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}> {infoData1.description}</div></div>
                </div>
                <div  className="row" style={{paddingLeft:"2%"}}>
             <div style={{ backgroundColor: "#e4dd9f5c", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>Manufacturer name</div></div>
             <div style={{  border: "2px solid white", borderRadius: "5px", }} className="col-8"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}> {infoData1.manufacturer_name}</div></div>
                </div>
                <div  className="row" style={{paddingLeft:"2%"}}>
             <div style={{ backgroundColor: "#e4dd9f5c", border: "2px solid white", borderRadius: "5px", }} className="col-4"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}>Manufacturer address</div></div>
             <div style={{  border: "2px solid white", borderRadius: "5px", }} className="col-8"><div style={{ paddingTop: "2%", paddingBottom: "2%", }}> {infoData1.manufacturer_address}</div></div>
                </div>
                              
          </div>}
        </Modal.Body>
      </Modal>
    );
  };

  const DeleteMedConfirmPopup = (props) => {


    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
       
        <Modal.Body>
        <svg xmlns="http://www.w3.org/2000/svg" width="7em" height="7em" viewBox="0 0 24 24"><path fill="#facea8" d="M11.5 3a9.5 9.5 0 0 1 9.5 9.5a9.5 9.5 0 0 1-9.5 9.5A9.5 9.5 0 0 1 2 12.5A9.5 9.5 0 0 1 11.5 3m0 1A8.5 8.5 0 0 0 3 12.5a8.5 8.5 0 0 0 8.5 8.5a8.5 8.5 0 0 0 8.5-8.5A8.5 8.5 0 0 0 11.5 4M11 17v-2h1v2zm0-4V8h1v5z"/></svg>
        {action === "in_stock" ? <h5 style={{wordWrap: "break-word",wordBreak:" break-word"}} id="">Please confirm if you want to mark this medicine as out of stock.</h5> :
            action === "out_stock" ? <h5 style={{wordWrap: "break-word",wordBreak:" break-word"}}id="">Please confirm if you want to mark this medicine as in stock.</h5> :
              action === "active" ? <h5 style={{wordWrap: "break-word",wordBreak:" break-word"}}id="">Please confirm if you want to mark this medicine as not permitted.</h5> :
                action === "inactive" ? <h5 style={{wordWrap: "break-word",wordBreak:" break-word"}}id="">Please confirm if you want to mark this medicine as permitted.</h5> : ""}

<button onClick={() => {
setMedConfirmModalShow(false);
}} type="button" className="swal2-cancel swal2-styled swal2-default-outline" aria-label="" style={{display: "inline-block",borderRadius:"5px",width:"25%", backgroundColor: "rgb(220, 53, 69)",color:"white",padding:"2%"}}>Cancel</button>
&nbsp;&nbsp;<button onClick={() => {

setMedConfirmModalShow(false);
handleDeleteMed();
}} type="button" className="swal2-confirm swal2-styled swal2-default-outline" aria-label="" style={{display: "inline-block",borderRadius:"5px",width:"25%", backgroundColor: "rgb(43, 105, 163)",color:"white",padding:"2%"}}>Confirm</button>   
        
       
        </Modal.Body>

      
        
      </Modal>
    );
  }
  const handleCallback = () => {
    featureList.splice(0, featureList.length + 1)
    setPage(1)
    setRefresh(!refresh)
  };

  useEffect(() => {
    handleCallback()
  }, [perPage, searchTermMed,searchTermATC,]);

  const renderATC = (cellInfo) => {


    return (
      <div style={{ color:!featureList[cellInfo.index].is_active?"red":!featureList[cellInfo.index].in_stock?"darkorange":""}}

      > {featureList[cellInfo.index][cellInfo.column.id]}</div>)
  }
  const renderDrug = (cellInfo) => {

    return (<div style={{ display: "flex", color:!featureList[cellInfo.index].is_active?"red":!featureList[cellInfo.index].in_stock?"darkorange":""}}>
      <div
        className=""
      > {(featureList.length > 0 && featureList[cellInfo.index][cellInfo.column.id])} </div></div>)
  }
  const columns = [

    {
      Header: "",
      Cell: props => {
        return (<div >

          {featureList[props.index].in_stock ?
            <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">in stock</Tooltip>}>
              <svg
                style={{

                  color: "#5a9569",
                  cursor: "pointer",
                }}
                onClick={() => { setAction("in_stock"); setID(featureList[props.index].id); setMedConfirmModalShow(true) }}
                xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="M6 18h5.5v-4H6zm0-5h5.5V6H6zm6.5 5H18v-7h-5.5zm0-8H18V6h-5.5zM5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v2h2v2h-2v2h2v2h-2v2h2v2h-2v2q0 .825-.587 1.413T19 21zm0-2h14V5H5zM5 5v14z" /></svg>
            </OverlayTrigger> :
            <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">out of stock</Tooltip>}>
              <svg style={{

                color: "silver",
                cursor: "pointer",
              }}
                onClick={() => { setAction("out_stock"); setID(featureList[props.index].id); setMedConfirmModalShow(true) }}
                xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="M6.308 17.692h5.038v-3.538H6.308zm0-4.846h5.038V6.308H6.308zm6.346 4.846h5.038v-6.538h-5.038zm0-7.846h5.038V6.308h-5.038zM5.616 20q-.691 0-1.153-.462T4 18.384V5.616q0-.691.463-1.153T5.616 4h12.769q.69 0 1.153.463T20 5.616v2.153h1.539v1H20V11.5h1.539v1H20v2.73h1.539v1H20v2.155q0 .69-.462 1.152T18.384 20z" /></svg>
            </OverlayTrigger>}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {featureList[props.index].is_active ? <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled"> Authorized</Tooltip>}>
            <svg
              style={{

                color: "green",
                cursor: "pointer",
              }}
              onClick={() => { setAction("active"); setID(featureList[props.index].id); setMedConfirmModalShow(true) }}
              xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 24 24"><path fill="currentColor" d="M16.5 6.5h-9C4.5 6.5 2 9 2 12s2.5 5.5 5.5 5.5h9c3 0 5.5-2.5 5.5-5.5s-2.5-5.5-5.5-5.5m0 8c-1.4 0-2.5-1.1-2.5-2.5s1.1-2.5 2.5-2.5S19 10.6 19 12s-1.1 2.5-2.5 2.5" /></svg></OverlayTrigger>
            : <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Unauthorized</Tooltip>}>
              <svg
                style={{

                  color: "red",
                  cursor: "pointer",
                }}
                onClick={() => { setAction("inactive"); setID(featureList[props.index].id); setMedConfirmModalShow(true) }}
                xmlns="http://www.w3.org/2000/svg" width="3em" height="3em" viewBox="0 0 24 24"><path fill="currentColor" d="M7 17q-2.083 0-3.542-1.457T2 12.005T3.458 8.46Q4.917 7 7 7h10q2.083 0 3.542 1.457T22 11.995t-1.458 3.544Q19.083 17 17 17zm0-1h10q1.65 0 2.825-1.175T21 12t-1.175-2.825T17 8H7Q5.35 8 4.175 9.175T3 12t1.175 2.825T7 16m-.002-1.5q1.04 0 1.771-.728t.731-1.77t-.728-1.771t-1.77-.731t-1.771.729t-.731 1.769t.729 1.771t1.769.731M12 12" /></svg>
            </OverlayTrigger>}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">med interaction</Tooltip>}>
            <svg onClick={()=>{
              if((featureList[props.index].med_interactions !== "null" && featureList[props.index].med_interactions !== "" && featureList[props.index].med_interactions !== null) ||
              (featureList[props.index].med_url !== "null" && featureList[props.index].med_url !== "" && featureList[props.index].med_url !== null)){
                setInfoData1(featureList[props.index]);
                setinfoShow(true);setdrugInt(true)
              }
              
            
            }} style={{
              cursor: (featureList[props.index].med_interactions !== "null" && featureList[props.index].med_interactions !== "" && featureList[props.index].med_interactions !== null) ||
                (featureList[props.index].med_url !== "null" && featureList[props.index].med_url !== "" && featureList[props.index].med_url !== null) ? "pointer" : "not-allowed"
            }} xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill={(featureList[props.index].med_interactions !== "null" && featureList[props.index].med_interactions !== "" && featureList[props.index].med_interactions !== null) ||
              (featureList[props.index].med_url !== "null" && featureList[props.index].med_url !== "" && featureList[props.index].med_url !== null) ? "Highlight" : "gray"} d="M4.47 21h15.06c1.54 0 2.5-1.67 1.73-3L13.73 4.99c-.77-1.33-2.69-1.33-3.46 0L2.74 18c-.77 1.33.19 3 1.73 3M12 14c-.55 0-1-.45-1-1v-2c0-.55.45-1 1-1s1 .45 1 1v2c0 .55-.45 1-1 1m1 4h-2v-2h2z" /></svg>
          </OverlayTrigger>

          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Info</Tooltip>}>
            <svg
               onClick={()=>{setInfoData1(featureList[props.index]);setinfoShow(true) ;setdrugInt(false)}}
              style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 256 256"><path fill="#FF9C01" d="M128 24a104 104 0 1 0 104 104A104.11 104.11 0 0 0 128 24m-4 48a12 12 0 1 1-12 12a12 12 0 0 1 12-12m12 112a16 16 0 0 1-16-16v-40a8 8 0 0 1 0-16a16 16 0 0 1 16 16v40a8 8 0 0 1 0 16" /></svg>
          </OverlayTrigger>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Edit</Tooltip>}>
            <svg style={{ cursor: "pointer" }} xmlns="http://www.w3.org/2000/svg" onClick={() => handleEdit(featureList[props.index])} width="2em" height="2em" viewBox="0 0 24 24"><g fill="var(--theme-blue)" fill-rule="evenodd" clip-rule="evenodd"><path d="M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352z" /><path d="M19.846 4.318a2.2 2.2 0 0 0-.437-.692a2 2 0 0 0-.654-.463a1.92 1.92 0 0 0-1.544 0a2 2 0 0 0-.654.463l-.546.578l2.852 3.02l.546-.579a2.1 2.1 0 0 0 .437-.692a2.24 2.24 0 0 0 0-1.635M17.45 8.721L14.597 5.7L9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.5.5 0 0 0 .255-.145l4.778-5.06Z" /></g></svg>
          </OverlayTrigger> </div>

        )
      },

      sortable: false,
      filterable: false,
      width: 230,

    },
    {
      Header: "Drug",
      accessor: "med_name",
      Cell: renderDrug,

      minWidth: 100,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },

      Filter: ({ filter, onChange }) => (
        // Custom filter input component

        <input
          value={searchTermMed}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue);
            setSearchTermMed(filterValue)
            // Update the filter value
            // filterData(filterValue); // Make an API request and update the filteredData state
          }}
          style={{ color: 'black' }}
        />
      ),


    },

    {
      Header: "ATC Code",
      accessor: "med_code",

      Cell: renderATC,
      sortable: true,
      filterable: true,
      style: { 'whiteSpace': 'unset' },

      filterMethod: (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined ? String(row[id]).toLowerCase().includes(filter.value.toLowerCase()) : true;
      },
      Filter: ({ filter, onChange }) => (
        // Custom filter input component

        <input
          value={searchTermATC}
          onChange={(e) => {
            const filterValue = e.target.value;
            onChange(filterValue);
            setSearchTermATC(filterValue)
           }}
          style={{ color: 'black' }}
        />
      ),
      width: 150
    },


  ]
  return (
    <>
      {/* <BackofficeNavBar/> */}
      <LoadingOverlay
        active={loading}
        spinner
        styles={{
          spinner: (base) => ({
            ...base,
            width: "50px",
            "& svg circle": {
              stroke: "rgba(0, 0, 255, 0.5)",
            },
          }),
          overlay: {},
        }}
      >
        <div className="displaylist-container ">

          <div className="row" style={{width:"100%"}}>
            <h5 style={{textAlign:"left", margin:"auto", paddingLeft:"2%",}}className="col-9"><b>Medicine Details</b>  </h5>
           <div className="col-3">
           <button
                        style={{padding:"1%",height:"40px",width:"100%"}}
                        className="assignbutton"
                        onClick={() => setAddRentalShow(true)}
                      >
                       Add Medicine <i class="fas fa-plus"></i>
                      </button>
           </div>
           
                      </div>
            <div className="current-table-holder container" style={{ marginTop: "1%" }}>

              <ReactTable

                data={featureList}
                columns={columns}
                resizable
                style={{ overflow: "wrap" }}
                className="service-req-table"
                defaultPageSize={perPage}
                showPagination={featureList.length > 0}
                pages={Math.ceil(totalRows / perPage)}
                page={page - 1}
                showPageJump={false}
                onPageChange={(pageIndex) => { handlePageChange(pageIndex); }}
                onPageSizeChange={(newPerPage) => { setPerPage(newPerPage); setPage(0); }}
                pageSizeOptions={[ totalRows]}
              />
              <br />

            </div>

         

          {editShow ? (
            <EditPopUp show={editShow} onHide={() => setEditShow(false)} />
          ) : null}

          {deleteShow ? (
            <DeleteConfirmPopup
              show={deleteShow}
              onHide={() => {
                setDeleteShow(false);
                setDeleteIndex("");
              }}
            />
          ) : (
            ""
          )}

          {successShow ? (
            <SubmitPopUp
              show={successShow}
              onHide={() => {
                setSuccessShow(false);
                setRefresh(!refresh);
              }}
            />
          ) : null}

          {addRentalShow ? (
            <AddRentalPopUp
              show={addRentalShow}
              onHide={() => setAddRentalShow(false)}
            />
          ) : null}
           {infoShow ? (
                <InfoPopup1
                    show={infoShow}
                    onHide={() => {
                        setinfoShow(false);
                    }}
                />
            ) : (
                ""
            )}
          {MedConfirmModalShow ?

            <DeleteMedConfirmPopup
              show={MedConfirmModalShow}
              onHide={() => setMedConfirmModalShow(false)}

            />

            :
            ''}
        </div>
      </LoadingOverlay>
    </>
  );
}

export default PharmMedicine;

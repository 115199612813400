import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { APIURL, TZ } from "../../../Global";
import bill from "../../../assets/images/bill.png";
import ZoomableImage from "./ZoomableImage";
import { Modal } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import PaymentInvoiceLiability from "./PaymentInvoiceLiability";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
function Liabilities(props) {
  const [liabilities, setLiabilities] = useState([])
  const [consentView, setConsentView] = useState(false);
  const [img, setImg] = useState("")
  const [due, setDue] = useState(0)
  const [paid, setPaid] = useState(0)
  const [checkIds, setCheckIds] = useState([])
  const [invoiceShow, setInvoiceShow] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const hospId = props.id
  useEffect(() => {setRefresh(!refresh)},[props.id])
  useEffect(() => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    axios.get(`${APIURL}/api/v1/staff/hospital-license-bills/?hospital_id=${props.id}&${TZ}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {
          setLiabilities(res.data.message)
        }


      })
      .catch(err => {

      })


  }, [refresh]);
  const ConsentPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body
          style={{ padding: "7%" }}
        >
          <div style={{ textAlign: "center" }}>
            <ZoomableImage src={img} />
          </div>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    );
  };
  const InvoicePopup20 = (props) => {
    // const consultData = config.find(item => { return item.name === 'Consultation' })

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>


        </Modal.Header>
        <Modal.Body className="text-center">
          <PaymentInvoiceLiability
            id={checkIds}
            hospId={hospId}
            due={due}
            paid={paid}
            onSuccess={() => {setCheckIds([]);setDue(0);setPaid(0); setInvoiceShow(false); setRefresh(!refresh);  }}
          />
        </Modal.Body>

      </Modal>
    );
  };
  const handleDue = () => {
    liabilities && liabilities.map((item) => { setDue(due + item.liability);setPaid(paid+item.paid_amount) })
  }
  return (
    <div style={{ marginBottom: "2%" }} className="row" >
      {liabilities.length > 0 ?


        <div className="" style={{}}>
          <h6 className="row"><span className="col-3" ></span>
            <div className="col-7"></div>
            <div className="col-2">

              {checkIds.length > 0 && <button onClick={() => { setInvoiceShow(true) }
              } style={{ backgroundColor: "rgb(85, 71, 159)" }} className="clinic_button">
                Generate invoice
              </button>}

            </div>

          </h6>
          <div className="row " style={{ backgroundColor: "rgba(85, 71, 159, 0.72)", color: "white", textAlign: "left", padding: "1%", borderRadius: "5px 5px 0px 0px" }}>
            
            <div className="col-2">Bill type</div>
            <div className="col-2"><b >Requested date</b></div>
            <div className="col-2"><b >Paid amount</b></div>
            <div className="col-2"><b >Total amount</b></div>
            <div className="col-2"><b >Liability</b></div>
            {/* <div className="col-2"><b >Payment status</b></div> */}
            <div className="col-1"><b >
              {liabilities.length === checkIds.length ?
                <svg onClick={(e) => { setCheckIds([]); setDue(0);setPaid(0) }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m9 11l3 3l8-8" /><path d="M20 12v6a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h9" /></g></svg>
                : <svg onClick={(e) => {
                  liabilities && liabilities.map((item) => {
                    setCheckIds((prevItems) =>
                      prevItems.includes(item.id) ? prevItems : [...prevItems, item.id]
                    ); setDue(0);setPaid(0); handleDue()
                  })
                }}
                  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M19 3H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m0 2v14H5V5z" /></svg>}

            </b></div>
            <div className="col-1"></div>

          </div>

          {liabilities && liabilities.map((item) => {
            return (<div className="row " style={{ textAlign: "left", borderLeft: "0.4px solid gray", borderBottom: "0.4px solid gray", borderRight: "0.4px solid gray" }}>
             
              <div style={{ padding: "1%" }} className="col-2">{item.bill_type}</div>
              <div style={{ padding: "1%" }} className="col-2">{new Date(item.bill_generated_date).toLocaleDateString()}</div>
              <div style={{ padding: "1%" }} className="col-2">RS {item.total_license_amount - item.liability}/-</div>
              <div style={{ padding: "1%" }} className="col-2">RS {item.total_license_amount}/-</div>
              <div onClick={(e) => { setImg(item.bill_image ? item.bill_image : bill); setConsentView(true); }}  style={{ padding: "1%", color: "orange" }} className="col-2"><b>RS {item.liability}/-</b>
             
              </div>
              <div style={{ padding: "1%" }} className="col-1" >
                {checkIds.includes(item.id) ? <svg onClick={(e) => { setCheckIds((prevItems) => prevItems.filter((i) => i !== item.id)); setDue(due - item.liability);setPaid(paid-item.paid_amount) }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><path d="m9 11l3 3l8-8" /><path d="M20 12v6a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h9" /></g></svg> :
                  <svg onClick={(e) => { setCheckIds((prevItems) => [...prevItems, item.id]); setDue(due + item.liability);setPaid(paid+item.paid_amount) }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M19 3H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2m0 2v14H5V5z" /></svg>}
             
              </div>
              <div
                className="col-1"> &nbsp;&nbsp;&nbsp;&nbsp; <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Resend bill</Tooltip>}>
                   <svg    onClick={(e) => {
                    const tokenString = sessionStorage.getItem('usertoken');

                    let str = tokenString.replace(/["]+/g, '')

                    axios.get(`${APIURL}/api/v1/staff/generate-provisional-bills/?bill_id=${item.id}&${TZ}`, {
                      headers: {

                        "Authorization": 'Token ' + str
                      }
                    })
                      .then(res => {

                        if (res.data.status === "success") {
                         alert.success(res.data.message)
                         setRefresh(!refresh)
                        }


                      })
                      .catch(err => {

                      })
                  }} xmlns="http://www.w3.org/2000/svg" style={{marginTop:""}} width="24" height="24" viewBox="0 0 24 24"><path fill="#2b69a3" d="M3 4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h10.5a6.5 6.5 0 0 1-.5-2.5a6.5 6.5 0 0 1 6.5-6.5a6.5 6.5 0 0 1 1.5.18V6a2 2 0 0 0-2-2zm0 2l8 5l8-5v2l-8 5l-8-5zm16 6l-2.25 2.25L19 16.5V15a2.5 2.5 0 0 1 2.5 2.5c0 .4-.09.78-.26 1.12l1.09 1.09c.42-.63.67-1.39.67-2.21c0-2.21-1.79-4-4-4zm-3.33 3.29c-.42.63-.67 1.39-.67 2.21c0 2.21 1.79 4 4 4V23l2.25-2.25L19 18.5V20a2.5 2.5 0 0 1-2.5-2.5c0-.4.09-.78.26-1.12z"/></svg>
                              
                             </OverlayTrigger></div>
            </div>
            )
          })}




        </div>

        :
        <div
          style={{
            color: "red",
            fontSize: "25px",
            height: "200px",
            padding: "10%",
          }}
        >
          <h4>No Liabilities!!</h4>
        </div>}
      {consentView ? (
        <ConsentPopUp
          show={consentView}
          onHide={() => setConsentView(false)}
        />
      ) : null}
      {invoiceShow ? (
        <InvoicePopup20
          show={invoiceShow}
          onHide={() => {
            setInvoiceShow(false);
            setCheckIds([])
            setDue(0);
            setPaid(0)
          }}
        />
      ) : null}
    </div>
  );
}
export default Liabilities;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link,useParams,useLocation,useNavigate  } from "react-router-dom";
import { APIURL,TZ } from "../../../Global";
import Pagination from "react-js-pagination";
import "../regdhospital.css"
import "../pagination.css";
import "../service.css"
import "../labsp.css"
import LoadingOverlay from 'react-loading-overlay';
import { ToggleButton } from 'react-bootstrap';
import { ToggleButtonGroup } from 'react-bootstrap';


function RegisteredHospitals() {
  const [selected, setSelected] = useState("pending")
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage,] = useState(10);const [approve, setApproved] = useState(false)
const navigate = useNavigate();
  const {status} = useParams();
 console.log(status)
  const { state } = useLocation()
 
 console.log(state)

  useEffect(() => {
    setLoading(true)
    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    axios.get(`${APIURL}/api/v1/staff/hospital-approve/` + (selected==="approved" ? "?status=approved" :selected==="rejected" ? "?status=rejected": ""), {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {
          const data = res.data.hospitals
          setPending(data)
        }
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })

  }, [selected])


  const handleChangeRadio = (val) => {
    setApproved(val)
  }

  const handleSearchChange = (e) => {
    e.preventDefault();
    setCurrentPage(1)
    setSearchTerm(e.target.value)
  }

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }
  


  let data = pending.sort((a, b) => {
    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
    return 0;
  })
    .filter((value) => {
      if (searchTerm === '') {
        return value
      } else if (value.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return value
      }
    })


  const indexLast = currentPage * perPage;
  const indexFirst = indexLast - perPage;
  const current = data.slice(indexFirst, indexLast);
  const total = data.length;

  const pageNumbers = []
  for (let i = 0; i <= Math.ceil(total / perPage); i++) {
    pageNumbers.push(i)
  }
  const totalPages = pageNumbers.length;

  let display = current.length > 0 ? current.map((data, index) => {
    let id = data.id

    return (

<> 
<div
              className="care-features"
              key={data.id}
              style={{ cursor: "pointer" }}
               onClick={() => {if(data.status!=="rejected") {navigate( `/hospital-details/${id}`)}}}
            >
              <div className="">
                <h5 className="feature-title " >
                  <b>
                    {" "}
                    <span className=""
                      style={{ cursor: "pointer"}}
                      onClick={() =>  {if(data.status!=="rejected") {navigate( `/hospital-details/${id}`)}}}
                    >
                     {(currentPage - 1) * 10 + index + 1}.{data.name}{" "}
                     
                    </span>
                    <small>ID : {data.id} </small>
                  </b>

                  {/* <i
                    class="fas fa-trash-alt"
                    onClick={() => handleDeletePopUp(item.id)}
                    style={{ float: "right", color: "red", cursor: "pointer" }}
                  ></i>
                  <i
                    class="fas fa-pencil-alt"
                    onClick={() => handleEdit(item)}
                    aria-hidden="true"
                    style={{
                      float: "right",
                      paddingRight: "25px",
                      color: "#5a9569",
                      cursor: "pointer",
                    }}
                  ></i> */}
                </h5>
              </div>

             
            </div>



   </> )
  }) : null
  const handleBack = (e) => {
    navigate(-1);
  };
  return (
  
      <div>
      
      
       
          <div className="container" style={{width:"82%",marginTop:"2%" ,minHeight:"71vh"}}>
          <div className="">
         
              <div className="container"  style={{width:"82%", }}>
                
              <div className='row'>
              <div className="col-12" style={{textAlign:"center",marginBottom:"2%"}}>
            <h4 className="" style={{color:"#55479F",paddingLeft:"2%"}}>CLINICS </h4>
            </div>
        <div className= {selected === "pending" ? "col-2 selectedoption" : 'col-2 selectoptions'} onClick={() => { setSelected("pending") }} >
          Pending
        </div>
        <div className={selected === "approved" ? "col-2 selectedoption" : 'col-2 selectoptions'} onClick={() => { setSelected("approved") }} >
          Approved
        </div>
        <div className={selected === "rejected" ? "col-2 selectedoption" : 'col-2 selectoptions'} onClick={() => { setSelected("rejected") }} >
          Rejected
        </div>
        <div className="col-4">
        <div className="search-div">
                <div style={{ display: "flex", position: "relative",height:"40px" }}>

                  <input

                    type="text"
                    className=""
                    placeholder="Search here"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    style={{ paddingLeft: "10px", width: "100%",borderRadius:"5px 0px 0px 5px",border:"1px solid " }}
                  />
                  <div style={{padding:"2.5%",borderRadius:"0px 5px 5px 0px"}}className="btn-primary btn-col search-btn">
                    {" "}
                    <i className="fas fa-search"></i>{" "}
                  </div>

                </div>
              </div>
        </div>
        <div className="col-2">
              <button className="" onClick={() => navigate("/Deregistered_splist")} style={{ boxShadow: "3px 5px 10px rgba(0,0,0,0.2)", height: "40px", fontSize: "small",  color: "white", backgroundColor: "#272244", border: "none",borderRadius:"5px" }}>
              Deregistered Clinics  <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </button>
            </div>
      </div>
            </div><br/>
              <div className="" style={{margin:"auto"}}>
                <div className="container" style={{width:"85%"}}>
                  <div className="test-reports-display-list">
                    {display ? (
                      display
                    ) : (
                      <div style={{ color: "", fontSize: "20px", height: "200px", padding: "10%" }}>
                       {!loading? <h5>{selected==="approved"?"No more approved list to show!!":selected==="pending"?"No more pending list to show!!":"No more rejected list to show!!"}</h5>:""}
                      </div>
                    )}
                  </div>

                </div>
              </div>

              {totalPages > 1 ?
                <div className="pagn pagn-small">
                  <br />
                  <br />
                  <br />
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={total}
                    pageRangeDisplayed={totalPages}
                    onChange={handlePageChange}
                    disabledClass="disabled-class"
                  />
                </div>
                : null}

            </div>
          </div>
        
      </div>
  )
}

export default RegisteredHospitals

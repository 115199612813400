import { APIURL,TZ } from "./Global";
import axios from "axios";
import img from "./assets/images/felixa_logo.png";
import React, { useState, useRef, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LoginPage from "./modules/login/LoginPage";
import ForgotPassword from "./modules/login/ForgotPassword";
import InputOtp from "./modules/login/InputOtp";
import LogoutPage from "./modules/login/LogoutPage";
import HomePage from "./modules/home/HomePage";
import StaffDashboard from "./modules/staff/Dashboard/Dashboard";
import Navbar from "./modules/common/Navbar";



import Footer from "./modules/common/Footer";
import RequireAuth from "./modules/registration/RequireAuth"
import { LoginProvider } from "./modules/contexts/LoginContext";
import { LoginStatusProvider } from "./modules/contexts/LoginStatusContext";
import { HospLoginProvider } from "./modules/contexts/HospitalLoginContext";
import { RegProvider } from "./modules/contexts/RegistrationContext";


import PageNotFound from "./modules/errorPage/PageNotFound";


import RegisteredHospitals from "./modules/staff/Clinics/RegisteredHospitals";
import DeregisteredHospitals from "./modules/staff/DeregisteredHospitals";
import HospitalDetails from "./modules/staff/Clinics/HospitalDetails";
import ServiceDetails from "./modules/staff/service/ServiceDetails"

import "react-datepicker/dist/react-datepicker.css";
import { ProfileProvider } from "./modules/contexts/ProfileContext";


import { ConfirmationProvider } from "./modules/contexts/SubmitConfirmationContext"

import MainServices from "./modules/staff/service/MainServices";
import Approvalpage from "./modules/staff/ApprovalImage/Approvalpage";

import Scan from "./modules/staff/AddScanTest/Scan";
import Test from "./modules/staff/AddLabTest/Test";
import ServiceOrderList from "./modules/staff/service/ServiceOrdersList";
import ServiceOrderReqDetails from "./modules/staff/service/ServiceOrderReqDetails";
import Configuration from "./modules/staff/Configuration/Configuration";



import ObservationSymptomsBO from "./modules/staff/ObservationAndSymptoms/ObservationSympotmsBO";
import Allergy from "./modules/staff/AddAllergy/Allergy";
import Misc from "./modules/staff/AddMisc/Misc";
import PharmMedicine from "./modules/staff/AddMedicines/PharmMedicine";
import PrimaryDiscease from "./modules/staff/AddPrimaryDiscease/PrimaryDiscease";

import Comorbidites from "./modules/staff/AddComobridities/Comorbidites"
import ProcedureList from "./modules/staff/ProcedureList/ProcedureList";


import Profile from "./modules/common/Profile/Profile"


import ConsumableList from "./modules/staff/Consumable/ConsumableList";

import AppUserDash from "./modules/ElderCare/AppUserDash";
import DataManagement from "./modules/staff/Dashboard/DataManagement";
import ConfigurationSales from "./modules/staff/SalesManagement/ConfigurationSales";
import AdminHome from "./modules/staff/Dashboard/AdminHome";
import SalesHome from "./modules/staff/Dashboard/SalesHome";
import StaffHome from "./modules/staff/Dashboard/StaffHome";
import LiabilitiesAll from "./modules/staff/clinicManagement/LiabilitiesAll";
import { ApiProvider } from "./modules/contexts/ApiContext";
import ClinicsMngmntHome from "./modules/staff/clinicManagement/ClinicsMngmntHome";


function App() {
 

  useEffect(() => {

    axios
      .get(`${APIURL}/api/v1/account/customer-info/?${TZ}`, {
        headers: {},
      })

      .then((res) => {

        if (res.data.status === "success") {
          sessionStorage.setItem("project_type", res.data.project_type);
            document.getElementById("favicon").setAttribute("href", res.data.app_logo)
            document.getElementById('title').innerHTML = res.data.message.app_name ? res.data.message.app_name : "Felixa Care";
        }
        else {
          document.getElementById("favicon").setAttribute("href", img)
        }
      })
  }, []);

  return (
    <>

      <BrowserRouter>
      <ConfirmationProvider>
        <RegProvider>
          <LoginStatusProvider>
            <HospLoginProvider>
              <ProfileProvider>
                <LoginProvider>
                <ApiProvider>
                 <Navbar /><br/><br/><br/>
                  <div className="test">


                    <Routes >
                      
                      <Route exact path="/" element={<RequireAuth><HomePage /></RequireAuth>} />
                       <Route exact path="/AppUser"  element={<RequireAuth type="appuser"><AppUserDash/></RequireAuth>} />
                      <Route exact path="/hospitallist" element={<RegisteredHospitals />} />
                      <Route exact path="/Deregistered_splist" element={<DeregisteredHospitals />} />
                      <Route exact path="/servicelist" element={<MainServices />} />
                      <Route exact path="/approvalpage" element={<Approvalpage />} />
                      <Route exact path="/clinisManagement" element={<ClinicsMngmntHome />} />
                      <Route exact path="/hospital-details/:id" element={<HospitalDetails />} />
                      <Route exact path="/service-details/:id" element={<ServiceDetails />} />
                      <Route exact path="/login" element={<LoginPage />} />
                   
                      <Route exact path="/logout" element={<LogoutPage />} />
       
                     
                     <Route exact path="/config_admin" element={<RequireAuth type="config_admin"><AdminHome /></RequireAuth>} />
                     <Route exact path="/sales_admin" element={<RequireAuth type="sales_admin"><SalesHome /></RequireAuth>} />
                     <Route exact path="/staff" element={<RequireAuth type="staff"><StaffHome /></RequireAuth>} />
                      <Route exact path="/404" element={<PageNotFound />} />
                     
                       <Route exact path="/doctor/profile" element={<Profile />} />
                  
                     
                      <Route exact path="/configuration" element={<Configuration />} />
                     
                     <Route exact path="/forgot-password" element={<ForgotPassword />} />
                     <Route exact path="/config-sales" element={<ConfigurationSales />} />
                     <Route exact path="/data-management" element={<DataManagement />} />
                     <Route exact path="/Scantest" element={<Scan />} />
                      <Route exact path="/Labtest" element={<Test />} />
                      <Route exact path="/observation-symptoms" element={<ObservationSymptomsBO />} />
                      <Route exact path="/allergy" element={<Allergy />} />
                      <Route exact path="/misc" element={<Misc />} />
                      <Route exact path="/medicine" element={<RequireAuth type="pharmacy"><PharmMedicine /></RequireAuth>} />
                      <Route exact path="/primary-disease" element={<PrimaryDiscease />} />
                      <Route exact path="/comorbidities" element={<Comorbidites />} />
                      <Route exact path="/procedures" element={<ProcedureList />} />
                      <Route exact path="/consumablelist" element={<ConsumableList />} />
                     
                      <Route exact path="/serviceorderlist" element={<ServiceOrderList />} />
                      <Route exact path="/serviceorderdetails/:id" element={<ServiceOrderReqDetails />} />
                    
                    
                    </Routes>
                  </div>
                  <Footer />
                 </ApiProvider>
                </LoginProvider>
              </ProfileProvider>
            </HospLoginProvider>

          </LoginStatusProvider>
        </RegProvider>
</ConfirmationProvider>
      </BrowserRouter>


    </>
  );
}

export default App;

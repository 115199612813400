import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useNavigate, NavLink, useLocation } from "react-router-dom";
import { LoginStatusContext } from "../contexts/LoginStatusContext";
import "./navbar.css";
import axios from "axios";
import { APIURL, TZ } from "../../Global";
import { LoginContext } from "../contexts/LoginContext";
import logo1 from "../../assets/images/ft_logo.png";
import logo2 from "../../assets/images/logo_name.png";
import logo from "../../assets/images/cp_logo.jpg";
import { ProfileContext } from "../contexts/ProfileContext";
import { NavDropdown } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";
import profile from "../../assets/images/icons/profile.png";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { SOCKETURL } from "../../Global";
import { RiUserLine } from "react-icons/ri";
import CallForAction from "./CallForAction";
import { useApiContext } from "../contexts/ApiContext";
// import orgData from "../../felixaconfig.json"

// import { useTranslation } from "react-i18next";

function Navbar(orgData) {
  const { state } = useLocation()
  const from = state !== null ? state.from : "";
  const webSocket = useRef(null);
  const [count, setCount] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useContext(LoginStatusContext);

  const [loginDatas] = useContext(LoginContext);
  const navigate = useNavigate();
  const [render] = useContext(ProfileContext);
  const [notify, setNotify] = useState(false)
  const [notifyHosp, setNotifyHosp] = useState(false)
  const [notifyCred, setNotifyCred] = useState(false)
  const [logoutConfirm, setLogoutConfirm] = useState(false);
  const [show, setShow] = useState(false);
  const [actionshow, setActionShow] = useState(false);
const [notifications,setNotifications]=useState([])
  const [read, setRead] = useState(false)
  const [refresh, setRefresh] = useState(false)
 
  const confirmButtonRef = useRef()
  const { isApiSuccess, setIsApiSuccess } = useApiContext()
  useEffect(() => {
    const handleKey = (e) => {

      if ((e.altKey && e.key === 'c') && confirmButtonRef.current) {
        e.preventDefault()
        setLogoutConfirm(false)
      } else if (e.key === 'Enter' && confirmButtonRef.current) {
        e.preventDefault()
        confirmButtonRef.current.click()
      }

    }
    document.addEventListener("keydown", handleKey); 

    return () => {
      document.removeEventListener("keydown", handleKey);
    }

  }, [confirmButtonRef.current]);
  const clinicRequest = () => {
    if(sessionStorage.getItem('usertoken')){
 
      const tokenString = sessionStorage.getItem('usertoken');
  
      let str = tokenString.replace(/["]+/g, '')
     
      axios.get(
        `${APIURL}/api/v1/staff/clinic-requests/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Token " + str,
        }
      })
          .then(res => {
  
              if (res.data.status === "success") {
                setNotifications(res.data.message)
                // setNotifications([{"message_count": 18,"message_type":"purchased_licenses1"},{"message_count": 8,"message_type":"purchased_licenses2"},{"message_count": 10,"message_type":"purchased_licenses3"}])
              }
  
  
          })
          .catch(err => {
  
          })
        
        }
  }
  useEffect(() => {setRefresh(!refresh)},[sessionStorage.getItem('usertoken')])
  useEffect(() => {
clinicRequest()

}, [refresh]);

useEffect(() => {
  if(isApiSuccess){
    setIsApiSuccess(false);
  clinicRequest()
  }
  }, [isApiSuccess,setIsApiSuccess]);


  const ref = useRef(null);
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas")) || null
  );
  useEffect(() => {

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);
  useEffect(() => {
    setLogin_datas(JSON.parse(sessionStorage.getItem("logindatas")));

  }, [loginDatas !== null]);


  const callLogout = () => {
    const tokenString = sessionStorage.getItem("usertoken");

    let str = tokenString.replace(/["]+/g, "");

    axios
      .get(`${APIURL}/api/v1/account/logout/`, {
        headers: {
          Authorization: "Token " + str,
          "Content-Type": "application/json",
        },
      })

      .then((res) => {
        if (res.data.status === "success") {
        } else {
        }
      })
      .catch((err) => { });

    navigate("/login");
  };

  const handleOpen = () => {
    setShow(!show);
  };
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false);
    }
  };
  const handleLogout1 = () => {
    setLogoutConfirm(true);
  };

  const handleLogout = () => {
    setLogoutConfirm(false);
    callLogout();
    setIsLoggedIn(false);

    sessionStorage.removeItem("role");
    sessionStorage.removeItem("userid");
    sessionStorage.removeItem("usertoken");
    sessionStorage.removeItem("usertype");
    sessionStorage.removeItem("logindatas");
    sessionStorage.removeItem("verified_login");
   
  };

  if (sessionStorage.getItem("usertoken")) {
    setIsLoggedIn(true);
  }

  const LogoutConfirmPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body style={{ display: 'flex', flexDirection: 'column' }}>
          <div><div className="alert-border"><span className="alert-icon">!</span></div></div>
          <label style={{ margin: '20px auto', fontSize: '18px', color: '#4e4e4e' }}>Please confirm to logout! </label>
          <div className="form-group" style={{ display: 'flex', margin: 'auto' }}>
            <button type="button" className='swirl-butns' style={{ margin: '10px 4px 0px 0px', backgroundColor: '#dc3545' }} onClick={props.onHide}> Cancel </button>
            <button type="submit" className='swirl-butns' style={{ margin: '10px 0px 0px 4px', backgroundColor: '#2b69a3' }} ref={confirmButtonRef} onClick={handleLogout} >Confirm </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  };
  useEffect(() => {
    if (login_datas) {
      handlingWebsocket();
    }
  }, [login_datas]);

  const handlingWebsocket = (props) => {
    const tokenString = sessionStorage.getItem("usertoken");
    if (tokenString) {
      let str = tokenString.replace(/["]+/g, "");
      webSocket.current = new WebSocket(
        `${SOCKETURL}/ws/service_request/staff/?token=` +
        str
      );
      webSocket.current.onopen = () => {
        console.log("WebSocket connectedffsfsdf");
      };
      webSocket.current.onmessage = (evt) => {
        if (evt && evt.data) {
          let message = JSON.parse(evt.data);
          if (message && message.message && message.message.unread_count) {
            setRefresh(!refresh)
            if(message.message.message_type==="purchased_licenses")
            setNotify(true)

          }
        }
      };
      webSocket.current.onclose = () => {
        console.log("disconnected");
      };
    }

  }

  const ActionPopup = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >

        <Modal.Body >
          <h6 style={{ marginTop: "-10px", marginLeft: "-10px", marginRight: "1%", padding: "1%", }}><b>Call for action</b>
            <button style={{ backgroundColor: "white", float: "right" }} variant="secondary" onClick={props.onHide}>
              <i class="fa fa-times" style={{ color: "black" }} aria-hidden="true"></i>
            </button></h6>
          <CallForAction count={count} onClose={props.onHide} />

        </Modal.Body>

      </Modal>
    );
  };

  function format(names) {
    if (names && names !== "") {
      const words = names.split(" ");
      const capitalizedWords = words.map(word => {
        const firstLetter = word.charAt(0).toUpperCase();
        const restOfWord = word.slice(1).toLowerCase();
        return firstLetter + restOfWord;
      });
      return capitalizedWords.join(" ");
    }
  }
  
  return (
    <>
      <nav style={{
        position: "fixed", height: '65px',
        width: '100%', zIndex: "1050"
      }} className="nav-bar">
        <div className="logo-div col-4">
          {isLoggedIn ? (
            <div style={{ display: "flex" }}>

              <h5 style={{ color: "white", textDecoration: "none", paddingTop: "5%" }}> <Link onClick={() => setRead(!read)} to="/"><img className="logo-img" src={logo1} alt="Logo" /></Link>
                <span>&nbsp;&nbsp;</span><b>Felixa Touch</b></h5>
            </div>
          ) : <div style={{ display: "flex" }}>

            <h5 style={{ color: "white", textDecoration: "none", paddingTop: "5%" }}><Link onClick={() => setRead(!read)} to="/login"><img className="logo-img" src={logo1} alt="Logo" /></Link>
              <span>&nbsp;</span><b> Felixa Touch</b></h5>
          </div>}
        </div>
        <div className="btns" >
          {isLoggedIn ? (

            login_datas.user_type === "config_admin" || login_datas.user_type === "sales_admin" || login_datas.user_type === "staff" ? (
              <>
                <NavLink onClick={() => setRead(!read)} exact to="/config_admin" activeClassName="active" className="inactive">
                  <i className="fas fa-home"></i> <b>{"DASHBOARD"}</b>
                </NavLink>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {notifications.length>0&&notifications.map((item)=>{return(<>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip  style={{ textTransform: "capitalize" }} id="tooltip-disabled">{item.message_type}</Tooltip>}>
                                      <NavLink to={false} activeClassName="active" className="inactive">
                  <button onClick={() => {
   
  if (item.message_type === "license_approval") {
    setNotify(false);
    setActionShow(true);
  } else if (item.message_type === "hospital_approval") {
    
    navigate("/hospitallist", {
      state: {
        approve: false,
      }})
  }
}}
                    style={{ backgroundColor: "#a49ad7",color:"white", borderRadius: "5px",fontSize:"10px" }}>{(item.message_type === "license_approval"&&notify)||(item.message_type === "hospital_approval"&&notifyHosp)||(item.message_type === "credit_request"&&notifyCred) ?<>&nbsp;<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="red" stroke="red" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"><g><path stroke-dasharray="4" stroke-dashoffset="4" d="M12 3v2"><animate fill="freeze" attributeName="stroke-dashoffset" dur="0.2s" values="4;0" /></path><path fill="lightred" fill-opacity="0" stroke-dasharray="28" stroke-dashoffset="28" d="M12 5c-3.31 0 -6 2.69 -6 6l0 6c-1 0 -2 1 -2 2h8M12 5c3.31 0 6 2.69 6 6l0 6c1 0 2 1 2 2h-8"><animate fill="freeze" attributeName="fill-opacity" begin="0.9s" dur="0.15s" values="0;0.3" /><animate fill="freeze" attributeName="stroke-dashoffset" begin="0.2s" dur="0.4s" values="28;0" /></path><animateTransform fill="freeze" attributeName="transform" begin="0.9s" dur="6s" keyTimes="0;0.05;0.15;0.2;1" type="rotate" values="0 12 3;3 12 3;-3 12 3;0 12 3;0 12 3" /></g><path stroke-dasharray="8" stroke-dashoffset="8" d="M10 20c0 1.1 0.9 2 2 2c1.1 0 2 -0.9 2 -2"><animate fill="freeze" attributeName="stroke-dashoffset" begin="0.6s" dur="0.2s" values="8;0" /><animateTransform fill="freeze" attributeName="transform" begin="1.1s" dur="6s" keyTimes="0;0.05;0.15;0.2;1" type="rotate" values="0 12 8;6 12 8;-6 12 8;0 12 8;0 12 8" /></path></g></svg>&nbsp;</>:""}
                    <span style={{ color: "white", borderRadius: "10px", fontSize: "14px", backgroundColor: "#55479f" }} >&nbsp;{item.message_count}&nbsp;</span>
                   </button>
                </NavLink></OverlayTrigger>&nbsp;&nbsp;</>
                )})}
               
                <NavDropdown
                  ref={ref}
                  style={{ zIndex: "1001" }}
                  title={
                    <>
                      <div style={{ display: "flex" }}>
                        <h6 style={{ color: "white" }} className="user-name">{login_datas && format(login_datas.name)}</h6>
                        <i
                          style={{
                            marginTop: "3%"
                          }}
                          class="fa fa-angle-down"

                        ></i>
                      </div>
                    </>
                  }
                  id="basic-nav-dropdown"
                  renderMenuOnMount={true}
                  noCaret

                  onClick={handleOpen}
                  show={show}
                >

                  <button
                    className="btn login-btn logout-btn"
                    onClick={handleLogout1}
                  >
                    Logout
                  </button>
                </NavDropdown>
              </>
            ) : (
              <>  <NavDropdown
                ref={ref}
                style={{ zIndex: "1001" }}
                title={
                  <>
                    <div style={{ display: "flex" }}>
                      <h6 className="user-name">{login_datas && format(login_datas.name)}</h6>
                      <i
                        style={{
                          marginTop: "3%"
                        }}
                        class="fa fa-angle-down"

                      ></i>
                    </div>
                  </>
                }
                id="basic-nav-dropdown"
                renderMenuOnMount={true}
                noCaret

                onClick={handleOpen}
                show={show}
              >

                <button
                  className="btn login-btn logout-btn"
                  onClick={handleLogout1}
                >
                  Logout
                </button>
              </NavDropdown>
              </>
            )
          ) : (
            <>

              <div className="">



              </div>

            </>
          )
          }

        </div>

      </nav>
      {logoutConfirm === true ? (
        <LogoutConfirmPopup
          show={logoutConfirm}
          onHide={() => {
            setLogoutConfirm(false);
          }}
        />
      ) : null}
      {actionshow === true ? (
        <ActionPopup
          show={actionshow}
          onHide={() => {
            setActionShow(false);
          }}
        />
      ) : null}
    </>
  );
}

export default Navbar;

import React, { useState, useEffect } from 'react'
import axios from "axios";
import { APIURL,TZ } from "../../../Global";
 import '../userprofile.css';

 import { useAlert } from 'react-alert';
import { Card, Button, Modal } from 'react-bootstrap'
import { Link, useParams,useLocation, useNavigate } from 'react-router-dom';
import BackButton from '../../common/BackButton';
import LicenseDetails from './LicenseDetails';
import Pricing from './Pricing';
import Liabilities from './Liabilities';

function HospitalDetails() {
  const alert = useAlert();
  const { id } = useParams(); 
  const [spstatus,setSpStatus]=useState('')
  const [img, setImg] = useState()
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [mail, setMail] = useState('');
  const [location, setLocation] = useState('');
  const [state1, setState1] = useState('')
  const [number, setNumber] = useState('')
  const [facilities, setFacilities] = useState([])
  const [country, setCountry] = useState('');
  const [website, setWebsite] = useState('');
  const [acc, setAcc] = useState('');
  const [year, setYear] = useState('');
  const [phone, setPhone] = useState('');
  const [emer, setEmer] = useState('');
  const [prMail, setPrMail] = useState('');
  const [regNum, setRegNum] = useState('');
  const [type, setType] = useState('');
  const [street, setStreet] = useState('');
  const [deliveryRadious, setDeliveryRadious] = useState('');
  const [status, setStatus] = useState("");
  const [deleteShow ,setDeleteShow] =useState(false);
  const [approveShow, setApproveShow] = useState(false);
  const [approveFail, setApproveFail] = useState(false);
  const [rejectShow, setRejectShow] = useState(false);
  const [rejectFail, setRejectFail] = useState(false);
  const [approveMsg, setApproveMsg] = useState('');
  const [approveFailMsg, setApproveFailMsg] = useState('');
  const [rejectMsg, setRejectMsg] = useState('');
  const [rejectFailMsg, setRejectFailMsg] = useState('');
  const [confirm, setConfirm] = useState(false);
  const [modalShow, setModalShow] = useState(false)
  const [modalShowdel, setModalShowdel] = useState(false)
  const [errorShow, setErrorShow] = useState(false)
  const [dlt, setdlt] = useState(false)
 const [isDergstr,setIsDergstr] = useState(false)
  const { state } = useLocation()
  const [refresh,setRefresh]=useState(false)
  console.log(state)
 const [selected,setSelected]=useState(state?state.selected:"home")
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas")) || null
  );
  let navigate = useNavigate();


  useEffect(() => {
   getProfile()

  }, [refresh])
  useEffect(() => {
   setRefresh(!refresh)
 
   }, [id])
const getProfile=()=>{

  const tokenString = sessionStorage.getItem("usertoken");

  let v2 = tokenString.replace(
    /["]+/g,
    ""
  );
  axios.get(`${APIURL}/api/v1/staff/hospital-profile/?hospital_id=${id}&${TZ}`, {
    headers: { Authorization: "Token " + v2 },
  })
    .then((res) => {
      if (res.data.status === 'success') {
        setSpStatus(res.data.account_status)
        const hospData = res.data.hospital_profile
        setImg(hospData.photo);
        setName(hospData.name);
        setDesc(hospData.description)
        setMail(hospData.email)
        setLocation(hospData.location)
        setState1(hospData.state)
        setNumber(hospData.mobile_number)
        setCountry(hospData.country)
        setWebsite(hospData.website)
        setAcc(hospData.accreditation)
        setYear(hospData.established_year)
        setPhone(hospData.contact_phone)
        setStatus(hospData.status)
        setPrMail(hospData.primary_email)
        setRegNum(hospData.reg_number)
        setType(hospData.health_care_type)
        setDeliveryRadious(hospData.delivery_radius)
        setStreet(hospData.street)
        setIsDergstr(hospData.is_deregister)
        if(res.data.message!==null){
          setdlt(true)
        }
        if (hospData.emergency_number === null) {
          setEmer('Not Available')
        }
        else {
          setEmer(hospData.emergency_number);
        }

        const facility = hospData.facilities.join(', ');
        setFacilities(facility)
        // const specializations =hospData.specializations.join(', ');
        //setSpecs(specializations);
      }
      else {
        setErrorShow(true)
      }
    })
    .catch((err) => {
      setErrorShow(true)
    })
}
  const handleDeleteModal= (status1)=> {
    setSpStatus(status1)
    setModalShowdel(true)
  }


  const handleApprove = () => {

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    const data = {
      hospital_id: id
    }
    axios.post(`${APIURL}/api/v1/staff/hospital-approve/`, data, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {
         
          alert.success(res.data.message)
         
            getProfile(); 
        }
        else {
          alert.error(res.data.message)
        }
      })
      .catch((err) => {
        setApproveFailMsg('Failed to submit data')
        setApproveFail(true)
      })

  }

  const handleDelete =() =>{

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
        /["]+/g,
        ""
      );
      let data ={}
     
      data ={            
        account_status :spstatus,
        hospital_email :mail
      }
  
      axios.post(`${APIURL}/api/v1/staff/disable_delete_account/`, data, {
        headers: { Authorization: "Token " + v2 },
   })
   .then((res) =>{
       if(res.data.status==='success'){
         
        alert.success(res.data.message)  
        spstatus==="Delete"&& navigate("/hospitallist", {
          state: {
            approve: true,
          }})
          getProfile();    

       }
       else{
        alert.error(res.data.message)
       }
   })
   .catch((err) => {
     
   })
  }


  const handleReject = () => {
   

    const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    );
    const data = {
      hospital_id: id,
      status: 'rejected'
    }
    axios.post(`${APIURL}/api/v1/staff/hospital-approve/`, data, {
      headers: { Authorization: "Token " + v2 },
    })
      .then((res) => {
        if (res.data.status === 'success') {
          navigate("/hospitallist", {
            state: {
              approve: true,
            }})
            
          alert.success(res.data.message)
        }
        else {
          alert.error(res.data.message)
        }
      })
      .catch((err) => {
        setRejectFailMsg('failed to reject')
        setRejectFail(true)
      })

  }

  // const getTime =()=> {
  //   let data = time;
  //   const date = new Date(time)
  //   let dateUpdated = date.toString()
  // }

  const modalHandle = () => {
    setModalShow(false)
  }

 

  const ApprovalPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
       
        <Modal.Body style={{textAlign:"center"}}>
        <br/>
          <h4 id="confirm-reject">Approved successfully</h4><br/><br/>
          <Button variant="secondary" onClick={() => navigate("/hospitallist", {
          state: {
            approve: false,
          }})}>Ok</Button>
        </Modal.Body>
        
      </Modal>
    )
  }
  const DeletePopUp = (props)=> {
    return(
      <Modal
          {...props}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
         
          <Modal.Body style={{textAlign:"center"}}><br/><br/>
          {spstatus==="Delete"?
              <h4 className='text-success'> Deleted successfully</h4>
            :
            spstatus==="Active"?
              <h4 className='text-success'> Activated successfully</h4>
            :
            
              <h4 className='text-success'> Deactivated successfully</h4>
            }
         <br/><br/>
         <Button variant="secondary" onClick={props.onHide}>Ok</Button>
           
          </Modal.Body>
         
        </Modal>
      )
  }

  const ApprovalFail = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        
        <Modal.Body style={{textAlign:"center"}}>
<br/>
          <h5 style={{ color: "red" }}> {approveFailMsg}!!</h5><br/><br/>
          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Body>
       
      </Modal>
    )
  }

  const RejectPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
      
        <Modal.Body style={{textAlign:"center"}}>
        <br/>
          <h4 id="confirm-reject">Service Provider rejected Successfully</h4>
          <br/><br/>
          <Button variant="secondary"  onClick={() => navigate("/hospitallist", {
          state: {
            approve: false,
          }})}>Ok</Button>
        </Modal.Body>
       
      </Modal>
    )
  }

  const RejectFail = (props) => {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
      
        <Modal.Body style={{textAlign:"center"}}>
<br/>
          <h5 style={{ color: "red" }}> Error in Data Submission. Please try again! </h5>
<br/><br/>
<Button variant="secondary" onClick={props.onHide}>Ok</Button>
        </Modal.Body>
       
      </Modal>
    )
  }
  const DelConfirmPopUp = (props)=> {
    return(
      <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
           backdrop="static"
            keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
             
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{textAlign:"center"}}>
           {spstatus==="Delete"?
              <h4 className='text-danger'>Are you sure to Delete this Service Provider ?</h4>
            :
            spstatus==="Active"?
              <h4 className='text-success'>Are you sure to Activate this Service Provider ?</h4>
            :
            
              <h4 className='text-danger'>Are you sure to Deactivate this Service Provider ?</h4>
            }
         
           
          </Modal.Body>
          <Modal.Footer>

             <Button variant="secondary" onClick={props.onHide}>No</Button>
             <Button variant="danger" onClick={props.onCall}>Yes</Button>          
    
          </Modal.Footer>
        </Modal>
      )
  }

  const ConfirmPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{textAlign:"center"}}>

          <h4 id="confirm-reject">Are you sure?</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>No</Button>
          <Button variant="danger" onClick={props.onReject}>Yes</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const ErrorPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{textAlign:"center"}}>

          {!dlt?<h5 style={{ color: "red" }}> Error in Data Retrieval. Please try again! </h5>:" "}

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

 return (<>
 <div className='row ' style={{marginTop:"1%",width:"100%",minHeight:"71vh"}}>


 
 <div className="col-3">
 <h6 class="" style={{paddingLeft:"4%",textTransform:"capitalize"}} ><b>{name}</b></h6>
    <div className="det-div " style={{padding:"1%"}}>
   
    <button className={selected==="home"?"patientRecbutton1":"patientRecbutton"}
style={{width:"100%",cursor: "pointer"}}
  onClick={() => {
   setSelected("home")
  }}
>
  &nbsp;
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48"><path fill="currentColor" fill-rule="evenodd" d="M20 4a3 3 0 0 0-3 3h-3a3 3 0 0 0-3 3v28a3 3 0 0 0 3 3h12v-2H14a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h3a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3h3a1 1 0 0 1 1 1v15h2V10a3 3 0 0 0-3-3h-3a3 3 0 0 0-3-3h-8Zm-1 3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1V7Zm4 12v-3h2v3h3v2h-3v3h-2v-3h-3v-2h3Zm4 9a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6.789a7 7 0 0 1-3.117 5.824l-3.328 2.22a1 1 0 0 1-1.11 0l-3.328-2.22A7 7 0 0 1 27 34.79V28Zm2 1v5.789a5 5 0 0 0 2.227 4.16L34 40.8l2.773-1.85A5 5 0 0 0 39 34.79V29H29Zm4.707 7.707l4-4l-1.414-1.414L33 34.586l-1.293-1.293l-1.414 1.414l2 2a1 1 0 0 0 1.414 0Z" clip-rule="evenodd" /></svg>
  &nbsp;
 Home{" "}

</button>
<button className={selected==="license"?"patientRecbutton1":"patientRecbutton"}
style={{width:"100%",cursor:login_datas.user_type === "staff"?"not-allowed": "pointer"}}
  onClick={() => {login_datas.user_type !== "staff"&&
   setSelected("license")
  }}
>
  &nbsp;
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 48 48"><path fill="currentColor" fill-rule="evenodd" d="M20 4a3 3 0 0 0-3 3h-3a3 3 0 0 0-3 3v28a3 3 0 0 0 3 3h12v-2H14a1 1 0 0 1-1-1V10a1 1 0 0 1 1-1h3a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3h3a1 1 0 0 1 1 1v15h2V10a3 3 0 0 0-3-3h-3a3 3 0 0 0-3-3h-8Zm-1 3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1V7Zm4 12v-3h2v3h3v2h-3v3h-2v-3h-3v-2h3Zm4 9a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v6.789a7 7 0 0 1-3.117 5.824l-3.328 2.22a1 1 0 0 1-1.11 0l-3.328-2.22A7 7 0 0 1 27 34.79V28Zm2 1v5.789a5 5 0 0 0 2.227 4.16L34 40.8l2.773-1.85A5 5 0 0 0 39 34.79V29H29Zm4.707 7.707l4-4l-1.414-1.414L33 34.586l-1.293-1.293l-1.414 1.414l2 2a1 1 0 0 0 1.414 0Z" clip-rule="evenodd" /></svg>
  &nbsp;
 License{" "}

</button>
<button className={selected==="pricing"?"patientRecbutton1":"patientRecbutton"}
style={{width:"100%",cursor:login_datas.user_type === "staff"?"not-allowed": "pointer"}}
  onClick={() => {login_datas.user_type !== "staff"&&
   setSelected("pricing")
  }}
>
  &nbsp;
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a2 2 0 1 0 0 4a2 2 0 0 0 0-4Zm-4 8.5a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1Z" /><path fill="currentColor" fill-rule="evenodd" d="M7 2.25A2.75 2.75 0 0 0 4.25 5v14A2.75 2.75 0 0 0 7 21.75h10A2.75 2.75 0 0 0 19.75 19V8.198a1.75 1.75 0 0 0-.328-1.02L16.408 2.98a1.75 1.75 0 0 0-1.421-.73H7ZM5.75 5c0-.69.56-1.25 1.25-1.25h7.25v4.397c0 .414.336.75.75.75h3.25V19c0 .69-.56 1.25-1.25 1.25H7c-.69 0-1.25-.56-1.25-1.25V5Z" clip-rule="evenodd" /></svg>
  &nbsp;
  Pricing{" "}

</button>
<button className={selected==="liabilities"?"patientRecbutton1":"patientRecbutton"}
style={{width:"100%",cursor:login_datas.user_type === "staff"?"not-allowed": "pointer",}}
  onClick={() => {login_datas.user_type !== "staff"&&
   setSelected("liabilities")
  }}
>
  &nbsp;
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M12 9a2 2 0 1 0 0 4a2 2 0 0 0 0-4Zm-4 8.5a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3a1 1 0 0 1-1 1H9a1 1 0 0 1-1-1Z" /><path fill="currentColor" fill-rule="evenodd" d="M7 2.25A2.75 2.75 0 0 0 4.25 5v14A2.75 2.75 0 0 0 7 21.75h10A2.75 2.75 0 0 0 19.75 19V8.198a1.75 1.75 0 0 0-.328-1.02L16.408 2.98a1.75 1.75 0 0 0-1.421-.73H7ZM5.75 5c0-.69.56-1.25 1.25-1.25h7.25v4.397c0 .414.336.75.75.75h3.25V19c0 .69-.56 1.25-1.25 1.25H7c-.69 0-1.25-.56-1.25-1.25V5Z" clip-rule="evenodd" /></svg>
  &nbsp;
  Liabilities{" "}

</button>




</div>
    </div>
    <div className="col-9" >
  {selected==="home"? <div>
   {dlt?
 <>
      
      
        <div className="det-div ">
        

          <div className="row " style={{padding:"2%"}}>
            <div className="col-sm-5">
              <p className=" text-left">ID :  <span className='text-info span-size'>{id}</span></p>
              <p></p>
            </div>
            <div className="col-sm-5">
              <p className="text-left">Registration Number : <span className='text-info span-size'>{regNum ? regNum : 'Not Available'}</span> </p>
              <p></p>
            </div>

            <div className="col-sm-5">
              <p className=" text-left">Health Care Type : <span className='text-info span-size'>{type === '' ? 'Not Available' : type}</span></p>
              <p></p>
            </div>

            <div className="col-sm-5">
              <p className="text-left">Established Year : <span className='text-info span-size'>{year === null ? 'Not Available' : year}</span></p>
              <p></p>
            </div>


            <div className="col-sm-5">
              <p className="text-left">Email : <span className='text-info span-size'>{mail === null ? 'Not Available' : mail}</span></p>
              <p></p>
            </div>

            <div className="col-sm-4">
              <p className="text-left">Location : <span className='text-info span-size'>{street}, {location}, {state1}, {country}</span></p>
              <p></p>
            </div>

            <div className="col-sm-5">
              <p className="text-left">Phone Number : <span className='text-info span-size'>{number === '' ? 'Not Available' : number}</span></p>
              <p></p>
            </div>

            <div className="col-sm-5">
              <p className="text-left">Emergency Number : <span className='text-info span-size'>{emer === null ? 'Not Available' : emer}</span></p>
              <p></p>
            </div>

            <div className="col-sm-5">
              <p className="text-left">Delivery Radius<small>(km)</small> : <span className='text-info span-size'>{deliveryRadious === null ? 'Not Available' : deliveryRadious}</span></p>
              <p></p>
            </div>

            <div className="col-sm-5">
              <p className="text-left">Website : <span className='text-info span-size'>{website === '' ? 'Not Available' : website}</span></p>
              <p></p>
            </div>

          
        </div>
   

     

</div>
{(login_datas.user_type === "staff"&& status==="pending")||(login_datas.user_type !== "staff")?<div className="det-div " style={{padding:"2%"}}>
 { status==="pending" ?
   <div className='' style={{textAlign:"center",justifyContent:"center"}}>
     <button  onClick={() => setConfirm(true)}style={{ boxShadow: "3px 5px 10px rgba(0,0,0,0.2)", height: "40px", fontSize: "small",  color: "white", backgroundColor: "red", border: "none",borderRadius:"5px" }}>Reject</button><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
     <button   onClick={handleApprove} style={{ boxShadow: "3px 5px 10px rgba(0,0,0,0.2)", height: "40px", fontSize: "small",  color: "white", backgroundColor: "#272244", border: "none",borderRadius:"5px" }}>Approve</button>
   </div>:
   
   <div className='' style={{textAlign:"center",justifyContent:"center"}}>
     {spstatus==="ACTIVE"?<button   onClick={(e)=> handleDeleteModal('Disable')} style={{ boxShadow: "3px 5px 10px rgba(0,0,0,0.2)", height: "40px", fontSize: "small",  color: "white", backgroundColor: "#fc9a03", border: "none",borderRadius:"5px" }}>Deactivate</button>:
     <button   onClick={(e)=> handleDeleteModal('Active')}style={{ boxShadow: "3px 5px 10px rgba(0,0,0,0.2)", height: "40px", fontSize: "small",  color: "white", backgroundColor: "#272244", border: "none",borderRadius:"5px" }}>Activate</button>}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
     <button   onClick={(e)=> handleDeleteModal('Delete')} style={{ boxShadow: "3px 5px 10px rgba(0,0,0,0.2)", height: "40px", fontSize: "small",  color: "white", backgroundColor: "red", border: "none",borderRadius:"5px" }}>Delete</button>
   </div> }
 </div>:""}</>
     : <div class="row">
    <div class="col-7">
      <h3 style={{color:"red"}}>This service provider is deleted</h3>
    </div>
  </div>}
  </div> 
  :selected==="license"?
  <div>
    <LicenseDetails id={id}/>
  </div>
  :selected==="pricing"?<Pricing id={id} handleAdd={() => { setSelected("license") }}/>
:<Liabilities id={id}/>}

      {
        approveMsg !== '' ?
          <ApprovalPopUp
            show={approveShow}
            onHide={() => { setApproveShow(false); setApproveMsg(''); }} /> : null
      }

      {
        approveFailMsg !== '' ?
          <ApprovalFail
            show={approveFail}
            onHide={() => { setApproveFail(false); setApproveFailMsg(''); }} /> : null
      }

      {
        rejectMsg !== '' ?
          <RejectPopUp
            show={rejectShow}
            onHide={() => { setRejectShow(false); setRejectMsg(''); }} /> : null
      }

      {
        rejectFailMsg !== '' ?
          <RejectFail
            show={rejectFail}
            onHide={() => { setRejectFail(false); setRejectFailMsg(''); }} /> : null
      }

      {
        confirm ?
          <ConfirmPopUp
            show={confirm}
            onHide={() => { setConfirm(false) }}
            onReject={() => { handleReject(); setConfirm(false) }} /> : null
      }

      {/* {
        modalShow ? 
        <ConfirmPopUp 
            show={modalShow}
            onCall={() => { handleDelete();  setModalShow(false) }}
            onHide={() => setModalShow(false) }/> : null
    } */}
      {
        modalShowdel ? 
        <DelConfirmPopUp 
            show={modalShowdel}
            onCall={() => { handleDelete();  setModalShowdel(false) }}
            onHide={() => setModalShowdel(false) }/> : null
    }

      {
        errorShow ?
          <ErrorPopUp
            show={errorShow}
            onHide={() => {
              setErrorShow(false);
            }} />
          : null
      }
    {deleteShow? 
  <DeletePopUp 
        show={deleteShow}
        onHide={() => { spstatus==="Delete"? navigate("/hospitallist", {
          state: {
            approve: true,
          }}):setDeleteShow(false);getProfile()}}        
      />

: null }


    </div>
    </div>
  </>

  )}



export default HospitalDetails

import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "./staffdashboard.css";
import axios from "axios";
import { APIURL, TZ } from "../../../Global";
import sp from "../../../assets/images/clinics.png";
import write from "../../../assets/images/icons/write.png";
import approval from "../../../assets/images/icons/approvalimage.jpg";
import payment from "../../../assets/images/icons/payment.png";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import VerifyOTP from "../../ConfigDashboard/VerifyOTP";
function Dashboard() {
  const navigate = useNavigate();
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas")) || null
  );
  let verified_login=sessionStorage.getItem("verified_login")
  const [verifyOTP, setVerifyOTP] = useState(false)
  useEffect(() => {
   if((login_datas.user_type === "config_admin"||login_datas.user_type === "sales_admin")&&verified_login==="false"){
    setVerifyOTP(true)
   }
}, [login_datas]);
  function OtpPopUp(props) {
    return (

        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static" keyboard={false}
        >
            <Modal.Body style={{}} >
                <VerifyOTP onSuccess={onSuccess} onCancel={onCancel} />
            </Modal.Body>

        </Modal>
    );
}
const onSuccess = () => {
  setVerifyOTP(false);

};
const onCancel = () => {

  setVerifyOTP(false);

  const tokenString = sessionStorage.getItem("usertoken");

  let str = tokenString.replace(/["]+/g, "");

  axios
    .get(`${APIURL}/api/v1/account/logout/`, {
      headers: {
        Authorization: "Token " + str,
        "Content-Type": "application/json",
      },
    })

    .then((res) => {
      if (res.data.status === "success") {
      } else {
      }
    })
    .catch((err) => { });
    sessionStorage.removeItem("role");
    sessionStorage.removeItem("userid");
    sessionStorage.removeItem("usertoken");
    sessionStorage.removeItem("usertype");
    sessionStorage.removeItem("logindatas");
    sessionStorage.removeItem(" aggr_type");
    sessionStorage.removeItem("verified_login");
  navigate("/login");
};
  return (
    <>


      <div className="home" style={{minHeight:"71vh"}} >
        <h3 className="title-of-page"> Service Management</h3>
        <div className="home-container">
        {(login_datas.user_type === "config_admin"||login_datas.user_type === "sales_admin"||login_datas.user_type === "staff" )&&<div className="home-tiles">
            <Link to="/hospitallist" style={{ textDecoration: "none" }}>
              <img src={sp} className="home-icon" width="65px" />
              <h4 className="each-title">Clinics </h4>

            </Link>
          </div>}
          {(login_datas.user_type === "config_admin"||login_datas.user_type === "sales_admin")&& <div className="home-tiles">
            <Link to="/config-sales" style={{ textDecoration: "none" }}>
              <img src={approval} className="home-icon" width="65px" />
              <h4 className="each-title"> Configuration </h4>

            </Link>
          </div>}
          {(login_datas.user_type === "config_admin"||login_datas.user_type === "sales_admin" )&&<div className="home-tiles">
            <Link to="/clinisManagement" style={{ textDecoration: "none" }}>
              <img src={payment} className="home-icon" width="65px" />
              <h4 className="each-title">Liabilities </h4>

            </Link>
          </div>}
           {(login_datas.user_type === "config_admin"||login_datas.user_type === "staff")&&<div className="home-tiles">
            <Link to="/data-management" style={{ textDecoration: "none" }}>
              <img src={write} className="home-icon" width="65px" />
              <h4 className="each-title"> Data management </h4>

            </Link>
          </div>}
        </div>
      </div>
      {verifyOTP ? (
                <OtpPopUp
                    show={verifyOTP}
                    onHide={() => {
                        setVerifyOTP(false);
                    }}
                />
            ) : null}
    </>
  );
}
export default Dashboard;

import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { APIURL, TZ } from "../../../Global";
import bill from "../../../assets/images/bill.png";
import remind from "../../../assets/images/remind.png";
import { Modal } from "react-bootstrap";
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import ZoomableImage from "../Clinics/ZoomableImage";
import { useAlert } from "react-alert";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
// import PaymentInvoiceLiability from "./PaymentInvoiceLiability";
function LiabilitiesAll() {
  const alert = useAlert();
  const [liabilities, setLiabilities] = useState([])
  const [consentView, setConsentView] = useState(false);
  const [img, setImg] = useState("")
  const [checkIds, setCheckIds] = useState([])
  const [invoiceShow, setInvoiceShow] = useState(false)
  const [refresh, setRefresh] = useState(false)
  let navigate = useNavigate();
  useEffect(() => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    axios.get(`${APIURL}/api/v1/staff/hospital-license-bills/?${TZ}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {
          setLiabilities(res.data.message)
        }


      })
      .catch(err => {

      })


  }, [refresh]);
  const ConsentPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body
          style={{ padding: "7%" }}
        >
          <div style={{ textAlign: "center" }}>
            <ZoomableImage src={img} />
          </div>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    );
  };
  const InvoicePopup20 = (props) => {
    // const consultData = config.find(item => { return item.name === 'Consultation' })

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>


        </Modal.Header>
        <Modal.Body className="text-center">
          {/* <PaymentInvoiceLiability
              id={checkIds}
            //   hospId={hospId}
              onSuccess={() => {setInvoiceShow(false); setRefresh(!refresh);setCheckIds([]) }}
            /> */}
        </Modal.Body>

      </Modal>
    );
  };
  return (
    <div className="" style={{  margin: "auto", minHeight: "71vh",fontSize:"14px" }}>
      <div style={{ marginBottom: "2%" }} className="row" >
        {liabilities.length > 0 ?


          <div className="" style={{}}>
            <h6 className="row"><span className="col-3" ></span>
              <div className="col-7"></div>
              <div className="col-2">

                {/* <button onClick={() => {  setInvoiceShow(true)  }
                         } style={{ backgroundColor:"rgb(85, 71, 159)" }} className="clinic_button">
                          Generate invoice
                         </button> */}

              </div>

            </h6>
            <div className="row " style={{ backgroundColor: "rgba(85, 71, 159, 0.72)", color: "white", textAlign: "left", padding: "1%", borderRadius: "5px 5px 0px 0px" }}>
              {/* <div  className="col-1">ID</div> */}
              <div className="col-2">Hospital</div>
              <div className="col-2">Bill type</div>
              <div className="col-2"><b >Generated</b></div>
              <div className="col-2"><b >Renewal</b></div>
              <div className="col-1"><b >Total </b></div>
              <div className="col-1"><b >Paid </b></div>
             
              <div className="col-1"><b >Liability</b></div>
              {/* <div className="col-2"><b >Payment status</b></div> */}
              <div className="col-1"></div>

            </div>

            {liabilities && liabilities.map((item) => {
              return (<div className="row " style={{ textAlign: "left", borderLeft: "0.4px solid gray", borderBottom: "0.4px solid gray", borderRight: "0.4px solid gray" }}>
                {/* <div  style={{ padding:"1%",color:"blue",cursor:"pointer"}}className="col-1">{item.id}</div> */}
                <div onClick={() => navigate(`/hospital-details/${item.hospital_id}`, {
                  state: {
                    selected: "liabilities",
                  }
                })} style={{ padding: "1%", textTransform: "capitalize", color: "blue", cursor: "pointer" }} className="col-2">{item.hospital_name}</div>
                <div style={{ padding: "1%" }} className="col-2"> {item.bill_type}</div>
                <div style={{ padding: "1%" }} className="col-2">{new Date(item.bill_generated_date).toLocaleDateString()}</div>
                <div style={{ padding: "1%" }} className="col-2"> {new Date(item.renewal_date).toLocaleDateString()}</div>
                <div style={{ padding: "1%" }} className="col-1">RS {item.total_license_amount}/-</div>
                <div style={{ padding: "1%" }} className="col-1">RS {item.total_license_amount - item.liability}/-</div>
                
                <div   className="col-2"><b style={{ padding: "1%", color: "orange", cursor: "pointer" }} onClick={(e) => { setImg(item.bill_image ? item.bill_image : bill); setConsentView(true); }}>RS {item.liability}/-</b>
                {/* <div style={{ padding:"1%"}}className="col-2">{item.payment_status}</div> */}
                <div style={{ padding: "0.5%",float:"right" }}  >
                   <OverlayTrigger overlay={<Tooltip style={{ textTransform: "capitalize" }} id="tooltip-disabled">Resend bill</Tooltip>}>
                   <svg    onClick={(e) => {
                    const tokenString = sessionStorage.getItem('usertoken');

                    let str = tokenString.replace(/["]+/g, '')

                    axios.get(`${APIURL}/api/v1/staff/generate-provisional-bills/?bill_id=${item.id}&${TZ}`, {
                      headers: {

                        "Authorization": 'Token ' + str
                      }
                    })
                      .then(res => {

                        if (res.data.status === "success") {
                         alert.success(res.data.message)
                         setRefresh(!refresh)
                        }


                      })
                      .catch(err => {

                      })
                  }} xmlns="http://www.w3.org/2000/svg" style={{marginTop:"4%"}} width="24" height="24" viewBox="0 0 24 24"><path fill="#2b69a3" d="M3 4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h10.5a6.5 6.5 0 0 1-.5-2.5a6.5 6.5 0 0 1 6.5-6.5a6.5 6.5 0 0 1 1.5.18V6a2 2 0 0 0-2-2zm0 2l8 5l8-5v2l-8 5l-8-5zm16 6l-2.25 2.25L19 16.5V15a2.5 2.5 0 0 1 2.5 2.5c0 .4-.09.78-.26 1.12l1.09 1.09c.42-.63.67-1.39.67-2.21c0-2.21-1.79-4-4-4zm-3.33 3.29c-.42.63-.67 1.39-.67 2.21c0 2.21 1.79 4 4 4V23l2.25-2.25L19 18.5V20a2.5 2.5 0 0 1-2.5-2.5c0-.4.09-.78.26-1.12z"/></svg>
                              
                             </OverlayTrigger>
                </div>
                </div>
              </div>
              )
            })}




          </div>

          :
          <div
            style={{
              color: "red",
              fontSize: "25px",
              height: "200px",
              padding: "10%",
            }}
          >
            <h4>No Liabilities!!</h4>
          </div>}
        {consentView ? (
          <ConsentPopUp
            show={consentView}
            onHide={() => setConsentView(false)}
          />
        ) : null}
        {invoiceShow ? (
          <InvoicePopup20
            show={invoiceShow}
            onHide={() => {
              setInvoiceShow(false);
              setCheckIds([])
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
export default LiabilitiesAll;

import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { APIURL, TZ } from "../../../Global";
import bill from "../../../assets/images/bill.png";
import remind from "../../../assets/images/remind.png";
import { Modal } from "react-bootstrap";
import { Button, Card, ListGroup, ListGroupItem } from "react-bootstrap";
import ZoomableImage from "../Clinics/ZoomableImage";
import { useAlert } from "react-alert";
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
// import PaymentInvoiceLiability from "./PaymentInvoiceLiability";
function Bills() {
  const alert = useAlert();
  const [liabilities, setLiabilities] = useState([])
  const [consentView, setConsentView] = useState(false);
  const [img, setImg] = useState("")
 
  const [history, setHistory] = useState([])
  const [invoiceShow, setInvoiceShow] = useState(false);
  const [isinvoice, setIsInvoice] = useState(false)
  const [refresh, setRefresh] = useState(false)
  let navigate = useNavigate();
  useEffect(() => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    axios.get(`${APIURL}/api/v1/staff/hospital-license-bills/?payment_status=paid&${TZ}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {
          setLiabilities(res.data.message)
        }


      })
      .catch(err => {

      })


  }, [refresh]);
  const ConsentPopUp = (props) => {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}

      >
        <Modal.Header closeButton></Modal.Header>

        <Modal.Body
          style={{ padding: "7%" }}
        >
          <div style={{ textAlign: "center" }}>
            <ZoomableImage src={img} />
          </div>
        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
    );
  };
  const InvoicePopup20 = (props) => {
    // const consultData = config.find(item => { return item.name === 'Consultation' })

    return (
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>


        </Modal.Header>
        <Modal.Body className="text-center">
        <div className="row " style={{ backgroundColor: "rgba(85, 71, 159, 0.72)", color: "white", textAlign: "left", padding: "1%", borderRadius: "5px 5px 0px 0px",margin:"auto" }}>
             
              <div className="col-5">Amound</div>
              <div className="col-5">Paid on</div>
              <div className="col-1">Invoice</div>
            

            </div>
            {history.map((item)=>{
              return(
            <div className="row " style={{ textAlign: "left", borderLeft: "0.4px solid gray", borderBottom: "0.4px solid gray", borderRight: "0.4px solid gray",margin:"auto" }}>
             
             <div style={{ padding: "1%" }} className="col-5">{item.paid_amount}</div>
             <div  style={{ padding: "1%" }}className="col-5">{new Date(item.payment_date).toLocaleDateString()}</div>
             <div  style={{ padding: "1%" }}className="col-1"><svg onClick={(e) => {setIsInvoice(true); setImg(item.invoice? item.invoice : bill);setInvoiceShow(false); setConsentView(true); }} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 48 48"><g fill="none" stroke="blue" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"><rect width="40" height="36" x="4" y="6" rx="3"/><path d="M4 14h40M20 24h16m-16 8h16m-24-8h2m-2 8h2"/></g></svg></div>
           

           </div>
              )
})}
        </Modal.Body>

      </Modal>
    );
  };
  return (
    <div className="" style={{  margin: "auto", minHeight: "71vh" ,fontSize:"14px"}}>
      <div style={{ marginBottom: "2%" }} className="row" >
        {liabilities.length > 0 ?


          <div className="" style={{}}>
            <h6 className="row"><span className="col-3" ></span>
              <div className="col-7"></div>
              <div className="col-2">

               
              </div>

            </h6>
            <div className="row " style={{ backgroundColor: "rgba(85, 71, 159, 0.72)", color: "white", textAlign: "left", padding: "1%", borderRadius: "5px 5px 0px 0px" }}>
              {/* <div  className="col-1">ID</div> */}
              <div className="col-2">Hospital</div>
              <div className="col-2">Bill type</div>
              <div className="col-2"><b >Generated</b></div>
              <div className="col-2"><b >Renewal</b></div>
              <div className="col-1"><b >Total </b></div>
              <div className="col-2"><b >Paid </b></div>
             
              <div className="col-1"><b >Liability</b></div>
              {/* <div className="col-2"><b >Payment status</b></div> */}
             


            </div>

            {liabilities && liabilities.map((item) => {
              return (<div className="row " style={{ textAlign: "left", borderLeft: "0.4px solid gray", borderBottom: "0.4px solid gray", borderRight: "0.4px solid gray" }}>
                {/* <div  style={{ padding:"1%",color:"blue",cursor:"pointer"}}className="col-1">{item.id}</div> */}
                <div onClick={() => navigate(`/hospital-details/${item.hospital_id}`, {
                  state: {
                    selected: "liabilities",
                  }
                })} style={{ padding: "1%", textTransform: "capitalize", color: "blue", cursor: "pointer" }} className="col-2">{item.hospital_name}</div>
                <div style={{ padding: "1%" }} className="col-2"> {item.bill_type}</div>
                <div style={{ padding: "1%" }} className="col-2">{new Date(item.bill_generated_date).toLocaleDateString()}</div>
                <div style={{ padding: "1%" }} className="col-2"> {new Date(item.renewal_date).toLocaleDateString()}</div>
                <div style={{ padding: "1%" }} className="col-1">RS {item.total_license_amount}/-</div>
                <div  className="col-2"style={{ padding: "1%", color: "orange", cursor: "pointer" }}><span  onClick={(e) => {setIsInvoice(false); setImg(item.bill_image ? item.bill_image : bill); setConsentView(true); }}>RS {item.total_license_amount - item.liability}/-</span>
                <svg onClick={(e) => { setHistory(item.payment_history);setInvoiceShow(true);}} style={{float:"right",marginRight:"20%"}} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16"><g fill="blue"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/><path d="m8.93 6.588l-2.29.287l-.082.38l.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319c.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246c-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0a1 1 0 0 1 2 0"/></g></svg>
                </div>
                
                <div   className="col-1">RS {item.liability}/-
                {/* <div style={{ padding:"1%"}}className="col-2">{item.payment_status}</div> */}
               
                </div>
              </div>
              )
            })}




          </div>

          :
          <div
            style={{
              color: "red",
              fontSize: "25px",
              height: "200px",
              padding: "10%",
            }}
          >
            <h4>No Bills!!</h4>
          </div>}
        {consentView ? (
          <ConsentPopUp
            show={consentView}
            onHide={() => {setConsentView(false);if(isinvoice){setInvoiceShow(true)}}}
          />
        ) : null}
        {invoiceShow ? (
          <InvoicePopup20
            show={invoiceShow}
            onHide={() => {
              setInvoiceShow(false);
             
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
export default Bills;
